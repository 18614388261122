import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { appSettings } from '../store/reducers/appSettingsSlice'

const Nav = () => {
    const { t } = useTranslation()
    const { isBurger } = useAppSelector((state) => state.appSettings)
    const { setIsBurger, setIsLangaugePopup } = appSettings.actions
    const dispatch = useAppDispatch()
    let ref = React.useRef()

    useOnClickOutside(ref, () => dispatch(setIsBurger(false)))

    return (
        <>
            <StyledNav isBurger={isBurger} ref={ref}>
                <Top>
                    <NavLink to="/">
                        <Logo>
                            {/* <img src="/images/primary/Logo.png" alt="BorB" /> */}
                            <img src="/images/primary/logo_new.webp" alt="BorB" />
                            <p>BorB</p>
                        </Logo>
                    </NavLink>
                    <Block>
                        <Title>{t('Navigation.Contracts')}</Title>
                        <NavLink to="/" className="colored">
                            <StyledLink>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    className="not-fill"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M18.3337 10H15.0003L12.5003 17.5L7.50033 2.5L5.00033 10H1.66699"
                                        stroke="#8A8F99"
                                        strokeWidth="1.4"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                                <p>{t('Navigation.CFD')}</p>
                            </StyledLink>
                        </NavLink>
                        <NavLink to="/FPC" className="colored">
                            <StyledLink>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 512 512"
                                    fill="none"
                                    style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                                >
                                    <g>
                                        <path
                                            fill="#8A8F99"
                                            d="M 113.5,-0.5 C 124.833,-0.5 136.167,-0.5 147.5,-0.5C 197.975,6.50227 231.475,34.1689 248,82.5C 260.584,132.026 248.417,174.193 211.5,209C 187.724,227.314 160.724,236.147 130.5,235.5C 77.7541,235.406 40.2541,211.739 18,164.5C 1.32231,118.904 7.65564,76.9043 37,38.5C 57.7066,16.0635 83.2066,3.06349 113.5,-0.5 Z M 126.5,54.5 C 167.997,55.3346 190.33,76.3346 193.5,117.5C 191.249,156.418 170.582,177.085 131.5,179.5C 105.331,179.341 85.8312,168.008 73,145.5C 62.8894,121.703 64.8894,99.0358 79,77.5C 91.7179,63.2234 107.551,55.5567 126.5,54.5 Z"
                                        />
                                    </g>
                                    <g>
                                        <path
                                            fill="#8A8F99"
                                            d="M 443.5,43.5 C 452.173,43.3336 460.84,43.5002 469.5,44C 476.039,47.3433 477.205,52.1766 473,58.5C 352.5,193 232,327.5 111.5,462C 106.828,464.837 101.828,466.837 96.5,468C 77.743,468.974 59.0763,468.64 40.5,467C 37.0977,463.281 36.5977,459.114 39,454.5C 158,322.167 277,189.833 396,57.5C 400.771,52.0639 406.605,48.2306 413.5,46C 423.617,44.9375 433.617,44.1041 443.5,43.5 Z"
                                        />
                                    </g>
                                    <g>
                                        <path
                                            fill="#8A8F99"
                                            d="M 398.5,511.5 C 387.5,511.5 376.5,511.5 365.5,511.5C 315.425,504.369 282.592,476.702 267,428.5C 255.847,381.927 267.014,341.76 300.5,308C 332.156,283.503 367.823,274.837 407.5,282C 457.463,292.63 488.296,322.797 500,372.5C 507.453,416.808 494.953,454.308 462.5,485C 443.778,500.184 422.445,509.017 398.5,511.5 Z M 370.5,333.5 C 416.443,330.451 441.443,351.784 445.5,397.5C 442.072,435.763 421.238,455.763 383,457.5C 353.059,457.541 333.059,443.541 323,415.5C 316.353,392.406 320.353,371.406 335,352.5C 344.849,342.327 356.682,335.994 370.5,333.5 Z"
                                        />
                                    </g>
                                </svg>
                                <p>{t('Navigation.FPC')}</p>
                            </StyledLink>
                        </NavLink>

                        {/*<NavLink to="/CFF" className="colored">
                            <StyledLink>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 512 512"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                >
                                    <path
                                        fill="#8A8F99"
                                        d="M 420.5,-0.5 C 438.833,-0.5 457.167,-0.5 475.5,-0.5C 477.807,0.977392 479.64,2.97739 481,5.5C 481.667,172.167 481.667,338.833 481,505.5C 479.64,508.023 477.807,510.023 475.5,511.5C 457.167,511.5 438.833,511.5 420.5,511.5C 417.458,509.776 415.625,507.109 415,503.5C 414.333,338.167 414.333,172.833 415,7.5C 415.625,3.89071 417.458,1.22405 420.5,-0.5 Z"
                                    />
                                    <path
                                        fill="#8A8F99"
                                        d="M 379.5,511.5 C 361.167,511.5 342.833,511.5 324.5,511.5C 322.193,510.023 320.36,508.023 319,505.5C 318.333,370.833 318.333,236.167 319,101.5C 320.251,98.6193 322.417,96.786 325.5,96C 343.167,95.3333 360.833,95.3333 378.5,96C 381.333,97.5 383.5,99.6667 385,102.5C 385.667,236.5 385.667,370.5 385,504.5C 384.033,507.621 382.2,509.954 379.5,511.5 Z"
                                    />
                                    <g>
                                        <path
                                            fill="#8A8F99"
                                            d="M 283.5,511.5 C 264.833,511.5 246.167,511.5 227.5,511.5C 224.658,509.156 222.825,506.156 222,502.5C 221.333,402.167 221.333,301.833 222,201.5C 222.614,197.885 224.447,195.051 227.5,193C 236.776,191.889 246.11,191.389 255.5,191.5C 264.89,191.389 274.224,191.889 283.5,193C 286.553,195.051 288.386,197.885 289,201.5C 289.667,301.833 289.667,402.167 289,502.5C 288.175,506.156 286.342,509.156 283.5,511.5 Z"
                                        />
                                    </g>
                                    <path
                                        fill="#8A8F99"
                                        d="M 186.5,511.5 C 168.167,511.5 149.833,511.5 131.5,511.5C 128.8,509.954 126.967,507.621 126,504.5C 125.333,434.833 125.333,365.167 126,295.5C 126.786,292.417 128.619,290.251 131.5,289C 149.833,288.333 168.167,288.333 186.5,289C 189,290.167 190.833,292 192,294.5C 192.667,364.833 192.667,435.167 192,505.5C 190.64,508.023 188.807,510.023 186.5,511.5 Z"
                                    />
                                    <path
                                        style={{ opacity: 0.985 }}
                                        fill="#8A8F99"
                                        d="M 90.5,511.5 C 72.1667,511.5 53.8333,511.5 35.5,511.5C 33.193,510.023 31.3597,508.023 30,505.5C 29.3333,467.167 29.3333,428.833 30,390.5C 31.4798,387.337 33.9798,385.504 37.5,385C 54.1667,384.333 70.8333,384.333 87.5,385C 92.3333,385.833 95.1667,388.667 96,393.5C 96.6667,430.167 96.6667,466.833 96,503.5C 95.3751,507.109 93.5418,509.776 90.5,511.5 Z"
                                    />
                                </svg>

                                <p>{t('Navigation.CFF')}</p>
                            </StyledLink>
                        </NavLink>*/}
                    </Block>
                    <Block>
                        <Title>{t('Navigation.Protocols')}</Title>
                        <NavLink className="need" to="/earn" onClick={() => dispatch(setIsBurger(false))}>
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9.9998 0.299805C10.3864 0.299805 10.6998 0.613205 10.6998 0.999805V3.46649H14.1665C14.5531 3.46649 14.8665 3.77989 14.8665 4.16649C14.8665 4.55309 14.5531 4.86649 14.1665 4.86649H7.91647C7.32857 4.86649 6.76476 5.10003 6.34905 5.51574C5.93335 5.93144 5.6998 6.49526 5.6998 7.08316C5.6998 7.67105 5.93335 8.23487 6.34905 8.65058C6.76476 9.06628 7.32857 9.29982 7.91647 9.29982H12.0831C13.0423 9.29982 13.9623 9.68087 14.6405 10.3591C15.3188 11.0374 15.6998 11.9573 15.6998 12.9165C15.6998 13.8757 15.3188 14.7956 14.6405 15.4739C13.9623 16.1521 13.0423 16.5332 12.0831 16.5332H10.6998L10.6998 18.9998C10.6998 19.3864 10.3864 19.6998 9.9998 19.6998C9.61321 19.6998 9.2998 19.3864 9.2998 18.9998V16.5332H4.9998C4.61321 16.5332 4.2998 16.2198 4.2998 15.8332C4.2998 15.4466 4.61321 15.1332 4.9998 15.1332H12.0831C12.671 15.1332 13.2349 14.8996 13.6506 14.4839C14.0663 14.0682 14.2998 13.5044 14.2998 12.9165C14.2998 12.3286 14.0663 11.7648 13.6506 11.3491C13.2349 10.9334 12.671 10.6998 12.0831 10.6998H7.91647C6.95727 10.6998 6.03736 10.3188 5.3591 9.64053C4.68085 8.96227 4.2998 8.04236 4.2998 7.08316C4.2998 6.12396 4.68085 5.20405 5.3591 4.52579C6.03736 3.84753 6.95727 3.46649 7.91647 3.46649H9.2998L9.2998 0.999805C9.2998 0.613205 9.61321 0.299805 9.9998 0.299805Z"
                                    fill="var(--grey60)"
                                />
                            </svg>
                            <p>{t('Navigation.Earn')}</p>
                        </NavLink>
                        <NavLink className="need" to="/supply" onClick={() => dispatch(setIsBurger(false))}>
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9.9998 1.2998C9.81415 1.2998 9.63611 1.37355 9.50483 1.50483C9.37355 1.63611 9.2998 1.81415 9.2998 1.9998V9.9998C9.2998 10.3864 9.6132 10.6998 9.9998 10.6998H17.9998C18.3864 10.6998 18.6998 10.3864 18.6998 9.9998C18.6998 8.8573 18.4748 7.72599 18.0376 6.67046C17.6003 5.61493 16.9595 4.65585 16.1516 3.84798C15.3438 3.04011 14.3847 2.39927 13.3292 1.96205C12.2736 1.52484 11.1423 1.2998 9.9998 1.2998ZM10.6998 9.2998V2.73344C11.4184 2.80267 12.1241 2.97823 12.7934 3.25548C13.6791 3.62234 14.4838 4.16006 15.1617 4.83793C15.8396 5.51579 16.3773 6.32054 16.7441 7.20622C17.0214 7.87556 17.1969 8.58122 17.2662 9.2998H10.6998ZM6.96561 3.64114C7.3198 3.48621 7.48134 3.07348 7.32641 2.71928C7.17148 2.36508 6.75875 2.20354 6.40456 2.35847C5.13063 2.9157 4.01245 3.77717 3.14768 4.86742C2.28292 5.95766 1.69787 7.2435 1.44354 8.6125C1.18921 9.98149 1.27331 11.3921 1.68853 12.721C2.10374 14.0499 2.83746 15.2569 3.82569 16.2362C4.81392 17.2155 6.02659 17.9374 7.35773 18.3385C8.68887 18.7397 10.0978 18.808 11.4614 18.5373C12.825 18.2666 14.1015 17.6652 15.1794 16.7859C16.2572 15.9066 17.1037 14.7761 17.6448 13.4935C17.795 13.1373 17.6281 12.7267 17.2719 12.5764C16.9157 12.4262 16.5051 12.5931 16.3548 12.9493C15.9025 14.0216 15.195 14.9664 14.2944 15.7011C13.3938 16.4358 12.3275 16.938 11.1888 17.1641C10.0501 17.3901 8.87343 17.3332 7.76172 16.9981C6.65 16.663 5.63692 16.0601 4.81113 15.2417C3.98534 14.4234 3.37197 13.4146 3.02482 12.3035C2.67766 11.1924 2.60733 10.0129 2.81999 8.86821C3.03265 7.72352 3.5218 6.64861 4.24454 5.73743C4.96727 4.82625 5.90155 4.10656 6.96561 3.64114Z"
                                    fill="#8A8F99"
                                />
                            </svg>
                            <p>{t('Navigation.Supply')}</p>
                        </NavLink>
                    </Block>
                    <Block>
                        <Title>{t('Navigation.About')}</Title>
                        <NavLink
                            className="need"
                            to="https://docs.borb.fi/"
                            onClick={() => dispatch(setIsBurger(false))}
                            target="_blank"
                        >
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                className="not-fill"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.833 1.6665H4.99967C4.55765 1.6665 4.13372 1.8421 3.82116 2.15466C3.5086 2.46722 3.33301 2.89114 3.33301 3.33317V16.6665C3.33301 17.1085 3.5086 17.5325 3.82116 17.845C4.13372 18.1576 4.55765 18.3332 4.99967 18.3332H14.9997C15.4417 18.3332 15.8656 18.1576 16.1782 17.845C16.4907 17.5325 16.6663 17.1085 16.6663 16.6665V7.49984L10.833 1.6665Z"
                                    stroke="#8A8F99"
                                    strokeWidth="1.4"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10.833 1.6665V7.49984H16.6663"
                                    stroke="#8A8F99"
                                    strokeWidth="1.4"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>

                            <p>{t('Navigation.Docs')}</p>
                        </NavLink>
                    </Block>
                </Top>
                <Language
                    onClick={() => {
                        dispatch(setIsLangaugePopup(true))
                        dispatch(setIsBurger(false))
                    }}
                >
                    <img src="/images/primary/language.svg" alt="Language" />
                    <p>English</p>
                    <img src="/images/primary/arrow2.svg" alt="Arrow" />
                </Language>
                <Bottom>
                    <Icons>
                        <a href="https://twitter.com/borb_fi" target="_blank" rel="noreferrer">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10 20C15.5234 20 20 15.5228 20 10C20 4.47717 15.5234 0 10 0C4.47656 0 0 4.47717 0 10C0 15.5228 4.47656 20 10 20ZM9.05469 10.4998L5.1875 4.5H7.91211L10.5723 8.62842L13.9199 4.5H14.7832L10.9551 9.22205L15 15.5H12.2773L9.4375 11.0934L5.86328 15.5H5L9.05469 10.4998Z"
                                    fill="#D9D9D9"
                                />
                            </svg>
                        </a>
                        <a href="https://github.com/orgs/borb-fi/" target="_blank" rel="noreferrer">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_173_758)">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10 0C4.475 0 0 4.475 0 10C0 14.425 2.8625 18.1625 6.8375 19.4875C7.3375 19.575 7.525 19.275 7.525 19.0125C7.525 18.775 7.5125 17.9875 7.5125 17.15C5 17.6125 4.35 16.5375 4.15 15.975C4.0375 15.6875 3.55 14.8 3.125 14.5625C2.775 14.375 2.275 13.9125 3.1125 13.9C3.9 13.8875 4.4625 14.625 4.65 14.925C5.55 16.4375 6.9875 16.0125 7.5625 15.75C7.65 15.1 7.9125 14.6625 8.2 14.4125C5.975 14.1625 3.65 13.3 3.65 9.475C3.65 8.3875 4.0375 7.4875 4.675 6.7875C4.575 6.5375 4.225 5.5125 4.775 4.1375C4.775 4.1375 5.6125 3.875 7.525 5.1625C8.325 4.9375 9.175 4.825 10.025 4.825C10.875 4.825 11.725 4.9375 12.525 5.1625C14.4375 3.8625 15.275 4.1375 15.275 4.1375C15.825 5.5125 15.475 6.5375 15.375 6.7875C16.0125 7.4875 16.4 8.375 16.4 9.475C16.4 13.3125 14.0625 14.1625 11.8375 14.4125C12.2 14.725 12.5125 15.325 12.5125 16.2625C12.5125 17.6 12.5 18.675 12.5 19.0125C12.5 19.275 12.6875 19.5875 13.1875 19.4875C15.1727 18.8173 16.8977 17.5415 18.1198 15.8395C19.3419 14.1376 19.9995 12.0953 20 10C20 4.475 15.525 0 10 0Z"
                                        fill="#C2C5CC"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_173_758">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a href="https://www.reddit.com/r/BorB_fi/" target="_blank" rel="noreferrer">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_173_757)">
                                    <path
                                        d="M6.83861 10.4444C6.72873 10.6089 6.67008 10.8022 6.67008 11C6.67008 11.1313 6.69595 11.2614 6.7462 11.3827C6.79646 11.504 6.87012 11.6142 6.96298 11.7071C7.05584 11.8 7.16608 11.8736 7.2874 11.9239C7.40873 11.9741 7.53876 12 7.67008 12C7.86787 12 8.06121 11.9413 8.22565 11.8315C8.3901 11.7216 8.51828 11.5654 8.59396 11.3827C8.66965 11.2 8.68946 10.9989 8.65087 10.8049C8.61228 10.6109 8.51704 10.4327 8.37719 10.2929C8.23734 10.153 8.05916 10.0578 7.86517 10.0192C7.67119 9.98063 7.47013 10.0004 7.2874 10.0761C7.10468 10.1518 6.9485 10.28 6.83861 10.4444Z"
                                        fill="#C2C5CC"
                                    />
                                    <path
                                        d="M10.0101 14.52C10.8977 14.5568 11.7706 14.2847 12.4801 13.75V13.79C12.5063 13.7644 12.5273 13.7339 12.5418 13.7002C12.5562 13.6664 12.5639 13.6302 12.5644 13.5935C12.5649 13.5569 12.5581 13.5204 12.5445 13.4864C12.5309 13.4523 12.5107 13.4213 12.4851 13.395C12.4595 13.3687 12.4289 13.3478 12.3952 13.3333C12.3615 13.3188 12.3253 13.3112 12.2886 13.3107C12.2145 13.3098 12.1431 13.3383 12.0901 13.39C11.4832 13.8254 10.7459 14.0406 10.0001 14C9.2552 14.0333 8.52131 13.811 7.92008 13.37C7.86827 13.3275 7.8025 13.3057 7.73555 13.309C7.6686 13.3123 7.60527 13.3404 7.55787 13.3878C7.51048 13.4352 7.48241 13.4985 7.47912 13.5655C7.47583 13.6324 7.49756 13.6982 7.54008 13.75C8.24956 14.2847 9.12247 14.5568 10.0101 14.52Z"
                                        fill="#C2C5CC"
                                    />
                                    <path
                                        d="M11.7445 11.8715C11.909 11.9813 12.1023 12.04 12.3001 12.04L12.2901 12.08C12.4257 12.0815 12.5602 12.0553 12.6853 12.0032C12.8105 11.9511 12.9238 11.874 13.0183 11.7767C13.1127 11.6795 13.1864 11.564 13.2349 11.4373C13.2833 11.3107 13.3055 11.1755 13.3001 11.04C13.3001 10.8422 13.2414 10.6489 13.1316 10.4844C13.0217 10.32 12.8655 10.1918 12.6828 10.1161C12.5 10.0404 12.299 10.0206 12.105 10.0592C11.911 10.0978 11.7328 10.193 11.593 10.3329C11.4531 10.4727 11.3579 10.6509 11.3193 10.8449C11.2807 11.0389 11.3005 11.24 11.3762 11.4227C11.4519 11.6054 11.5801 11.7616 11.7445 11.8715Z"
                                        fill="#C2C5CC"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM16.4066 9.21537C16.5683 9.44602 16.6598 9.71852 16.6701 10C16.6743 10.2755 16.6005 10.5466 16.4572 10.782C16.3139 11.0174 16.1069 11.2073 15.8601 11.33C15.8713 11.4764 15.8713 11.6235 15.8601 11.77C15.8601 14.01 13.2501 15.83 10.0301 15.83C6.81008 15.83 4.20008 14.01 4.20008 11.77C4.18882 11.6235 4.18882 11.4764 4.20008 11.33C4.00774 11.2416 3.83692 11.1125 3.69945 10.9515C3.56197 10.7906 3.46114 10.6017 3.40394 10.3979C3.34673 10.1941 3.33453 9.9803 3.36816 9.77132C3.4018 9.56234 3.48047 9.36317 3.59873 9.18762C3.71699 9.01207 3.872 8.86433 4.05303 8.75464C4.23407 8.64495 4.43678 8.57594 4.64714 8.55239C4.85749 8.52883 5.07045 8.5513 5.27126 8.61823C5.47207 8.68516 5.65593 8.79495 5.81008 8.94C6.96236 8.15852 8.31797 7.73099 9.71008 7.71L10.4501 4.24C10.4584 4.19983 10.4746 4.16171 10.4977 4.12784C10.5209 4.09398 10.5505 4.06504 10.585 4.04272C10.6194 4.0204 10.6579 4.00513 10.6982 3.99779C10.7386 3.99046 10.78 3.99121 10.8201 4L13.2701 4.49C13.3897 4.28444 13.5787 4.12825 13.8032 4.04948C14.0276 3.9707 14.2727 3.9745 14.4946 4.06018C14.7165 4.14587 14.9006 4.30784 15.0138 4.517C15.127 4.72617 15.162 4.96885 15.1124 5.20147C15.0628 5.4341 14.9319 5.64143 14.7433 5.78626C14.5546 5.9311 14.3205 6.00394 14.083 5.99172C13.8454 5.9795 13.62 5.88301 13.4472 5.71958C13.2744 5.55615 13.1655 5.33648 13.1401 5.1L11.0001 4.65L10.3501 7.77C11.7253 7.7995 13.0625 8.22671 14.2001 9C14.4033 8.80496 14.6586 8.67286 14.9352 8.61964C15.2118 8.56642 15.4979 8.59434 15.759 8.70004C16.02 8.80575 16.245 8.98471 16.4066 9.21537Z"
                                        fill="#C2C5CC"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_173_757">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a href="https://t.me/borb_fi" target="_blank" rel="noreferrer">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10 20C15.5234 20 20 15.5228 20 10C20 4.47717 15.5234 0 10 0C4.47656 0 0 4.47717 0 10C0 15.5228 4.47656 20 10 20ZM3.56055 9.13867L14.1895 5.0658C14.4531 4.94727 14.7129 4.99182 14.8652 5.17566C14.9922 5.3302 15.043 5.58301 14.959 5.92004L13.1484 14.3959C13.0859 14.6885 12.9688 14.8728 12.8066 14.9531C12.6328 15.0383 12.4102 15.0067 12.1484 14.8635L9.39062 12.8398L8.06641 14.1207L8.04883 14.1376C7.90234 14.2784 7.7793 14.3947 7.51367 14.3947L7.70117 11.599L12.8184 7.01135C13.041 6.8136 12.7695 6.71643 12.4707 6.89697L6.15625 10.8556L3.42773 10.0096C2.83984 9.83032 2.83398 9.42822 3.56055 9.13867Z"
                                    fill="#D9D9D9"
                                />
                            </svg>
                        </a>
                    </Icons>

                    <Row>
                        <BottomText>© {new Date().getFullYear()} BorB</BottomText>
                    </Row>
                </Bottom>
            </StyledNav>
        </>
    )
}

export default Nav

const Language = styled.div`
    display: none;
    @media screen and (max-width: 1280px) {
        display: flex;
        align-items: center;
        margin-top: auto;
        padding: 0 24px;
        cursor: pointer;
        p {
            margin-left: 8px;
            color: var(--grey60);
            font-weight: 400;
            font-size: 14px;
        }
    }
`

const StyledNav = styled.div<{
    theme?: any | null
    isBurger: boolean
    stroke?: boolean | null
    ref: any
}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-bottom: 24px;
    background: ${({ theme }) => theme.navbarBg};
    box-shadow: 6px 0px 16px rgba(10, 31, 51, 0.05);
    transition: 0.5s all ease;
    transform: translateX(0);
    @media screen and (max-width: 1280px) {
        padding-top: 24px;
        padding-bottom: 32px;
        width: 240px;
        position: fixed;
        z-index: 10;
        left: 0;
        transform: ${({ isBurger }) => (isBurger ? 'translateX(0)' : 'translateX(-240px)')};
    }

    a.colored {
        &.active {
            path {
                stroke: var(--pink);
                fill: ${({ stroke }) => (stroke ? 'none' : 'var(--pink)')};
                transition: 0.2s all ease;
            }
            p {
                color: var(--pink);
                font-weight: 600;
                transition: 0.2s all ease;
            }
        }
        &:hover {
            path {
                stroke: ${({ theme, stroke }) => (stroke ? theme.navbarHoverFill : 'none')};
                fill: ${({ theme, stroke }) => (stroke ? 'none' : theme.navbarHoverFill)};
                transition: 0.2s all ease;
            }
        }
    }

    a.need {
        cursor: pointer;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        margin: 0 9px;

        &.active {
            path {
                stroke: ${({ stroke }) => (stroke ? 'var(--pink)' : 'none')};
                fill: ${({ stroke }) => (stroke ? 'none' : 'var(--pink)')};
                transition: 0.2s all ease;
            }
            p {
                color: var(--pink);
                font-weight: 600;
                transition: 0.2s all ease;
            }
        }

        p {
            color: var(--grey60);
            font-weight: 400;
            font-size: 14px;
            margin-left: 8px;
        }

        &:hover {
            transition: 0.2s all ease;
            background: ${({ theme }) => theme.navbarHover};
            border-radius: 8px;
            path {
                stroke: ${({ theme, stroke }) => (stroke ? theme.navbarHoverFill : 'none')};
                fill: ${({ theme, stroke }) => (stroke ? 'none' : theme.navbarHoverFill)};
                transition: 0.2s all ease;
            }
            p {
                color: ${(props) => props.theme.navbarHoverFill};
                font-weight: 600;
                transition: 0.2s all ease;
            }
        }
    }

    svg.not-fill path {
        stroke: ${({ theme, stroke }) => (stroke ? 'none' : theme.navbarFill)}!important;
        fill: none !important;
    }

    a.active .not-fill path {
        stroke: var(--pink);
        fill: none !important;
    }

    a:hover svg.not-fill path {
        stroke: ${({ theme, stroke }) => (stroke ? 'none' : theme.navbarHoverFill)}!important;
        fill: none !important;
    }
`

const Block = styled.div`
    &:nth-child(n + 2) {
        margin-top: 40px;
    }

    @media screen and (max-width: 1280px) {
        margin: 0 !important;
        &:nth-child(n + 3) {
            margin-top: 24px !important;
        }
    }
`

const Top = styled.div``

const Logo = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 44px;
    padding: 24px 24px 0;
    img {
        width: 20px;
        height: 28px;
    }
    p {
        margin-left: 8px;
        font-weight: 600;
        font-size: 24px;
        color: ${(props) => props.theme.faqColor};
    }
    @media screen and (max-width: 1280px) {
        display: none;
    }
`

const Title = styled.h4`
    padding: 0 24px;
    font-weight: 400;
    color: var(--grey60);
    margin-left: 1px;
    font-size: 12px;
    margin-bottom: 16px;
    @media screen and (max-width: 1280px) {
        margin-bottom: 12px;
    }
`

const StyledLink = styled.div<{ theme?: any; stroke?: any }>`
    cursor: pointer;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin: 0 9px;

    p {
        color: var(--grey60);
        font-weight: 400;
        font-size: 14px;
        margin-left: 8px;
    }

    &:hover {
        transition: 0.2s all ease;
        background: ${({ theme }) => theme.navbarHover};
        border-radius: 8px;

        path {
            stroke: ${({ theme, stroke }) => (stroke ? theme.navbarHoverFill : 'none')};
            fill: ${({ theme, stroke }) => (stroke ? 'none' : theme.navbarHoverFill)};
            transition: 0.2s all ease;
        }

        p {
            color: ${({ theme }) => theme.navbarHoverFill}!important;
            font-weight: 600;
            transition: 0.2s all ease;
        }
    }
`

const Bottom = styled.div`
    margin-top: auto;
    padding-top: 28px;
    border-top: 1px solid ${(props) => props.theme.borderColor};
    p {
        color: ${(props) => props.theme.navbarBottom};
    }

    @media screen and (max-width: 1280px) {
        display: none;
    }
`

const Icons = styled.div`
    padding: 0 25px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    a:not(:last-child) {
        margin-right: 12px;
    }
    svg {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
    path {
        fill: ${(props) => props.theme.navbarBottom};
    }
`

const Row = styled.div`
    padding: 0 25px;
    display: flex;
    align-items: center;
`

const BottomLink = styled.p`
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: var(--grey80);
`

const BottomText = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: var(--grey80);
`

const Line = styled.div`
    height: 15px;
    width: 1px;
    background: ${(props) => props.theme.navbarBottom};
    margin: 0 7px;
`
