import { createAsyncThunk } from '@reduxjs/toolkit'
import { ApiResponse } from '../../@types/ApiResponse'
import { ReferalRewardVm } from '../../@types/Game/ref'
import { ListResponse } from '../../@types/ListResponse'
import { axiosClient } from './axiosClient'

interface IReferalRewardsRequest {
    address: string
    pageNumber: number
    pageSize?: number
}

export const getRewards = createAsyncThunk<ApiResponse<ListResponse<ReferalRewardVm>>, IReferalRewardsRequest>(
    'referalRewards/getRewards',
    async (args, { rejectWithValue }) => {
        try {
            const response = await axiosClient.get<ApiResponse<ListResponse<ReferalRewardVm>>>(
                '/api/referalRewards/getRewards',
                {
                    params: {
                        address: args.address,
                        pageNumber: args.pageNumber,
                        pageSize: args.pageSize ?? 10,
                    },
                }
            )
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

interface IApyRequest {
    asset: number
}
export const getAPY = createAsyncThunk<ApiResponse<string>, IApyRequest>(
    'referalRewards/getApy',
    async (args, { rejectWithValue }) => {
        try {
            const response = await axiosClient.get<ApiResponse<string>>('/api/ReferalRewards/GetApy', {
                params: {
                    asset: args.asset,
                },
            })
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

interface IGetInvestedRequest {
    address: string
}
export const getInvested = createAsyncThunk<ApiResponse<string>, IGetInvestedRequest>(
    'referalRewards/GetInvested',
    async (args, { rejectWithValue }) => {
        try {
            const response = await axiosClient.get<ApiResponse<string>>('/api/ReferalRewards/GetInvested', {
                params: {
                    address: args.address,
                },
            })
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)