import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment'
import './BetChart.css'

export class BetChartSimple {
    constructor(element, options = {}) {
        this.options = options
        this.options.pad = this.options.pad != null ? this.options.pad : 10000
        this.bet = null
        this.chart = null
        this.element = element
        this.__makeChart()
    }

    destroy() {
        if (!this.chart) {
            return
        }

        this.chart.destroy()
        this.chart = null
        cancelAnimationFrame(this.drawFrame)
    }

    setOptions({ points, timeframe, theme }) {
        if (!points) {
            return
        }
        this.points = points
            .filter(p => p.timeStamp != null)
            .map(p => ({ x: Date.parse(p.timeStamp), y: p.value }))
            .sort((a, b) => a.x - b.x)
        this.points = this.points.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.x === value.x && t.y === value.y
            ))
        )
        this.timeframe = timeframe
        this.theme = theme
        this.__updateChart()
    }

    setBet(timeStamp, value) {
        if (timeStamp) {
            this.bet = { x: Date.parse(timeStamp), y: value }
            if (this.points) {
                this.__updateChart()
            }
            return
        }
        this.bet = null
        if (this.points) {
            this.__updateChart()
        }
    }

    __makeChart() {
        this.destroy()
        this.chart = new Chart(this.element, {
            type: 'line',
            options: {
                normalized: true,
                maintainAspectRatio: false,
                responsive: true,
                events: [],
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: {
                        display: false,
                        type: 'time',
                    },
                    y: {
                        display: false,
                    }
                }
            },
            data: {
                datasets: [
                    {
                        data: [],
                        fill: ctx => {
                            if (!ctx.chart.chartArea) {
                                return
                            }
                            if (ctx.chart.data.datasets[0].data.length === 0) {
                                return
                            }
                            return {
                                target: {
                                    value: this.bet ? this.bet.y : 0,
                                },
                                below: this.__belowGradient(),
                                above: this.__aboveGradient(),
                            }
                        },
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        pointHitRadius: 0,
                        pointBorderWidth: 0,
                        pointHoverBorderWidth: 0,
                        borderWidth: 1,
                        tension: 0.3,
                        spanGaps: true,
                        segment: {
                            borderColor: ctx => {
                                if (!ctx.chart.chartArea) {
                                    return null
                                }
                                return this.__getGradient()
                            }
                        },
                    },
                ]
            }
        })
    }

    __updateChart() {
        if (!this.chart) {
            return
        }
        let points = this.points
        if (!points.length) {
            return
        }
        points = points.filter(p => {
            if (p.x > points[0].x + this.timeframe * 1000 + 60000 + this.options.pad) {
                return false
            }
            return true
        })
        if (this.bet && (!points.length || points[0].x > this.bet.x)) {
            points.unshift(this.bet)
        }
        const dataset = this.chart.data.datasets[0]
        dataset.data = points
        const min = points.reduce((v, p) => Math.min(v, p.y), points[0].y)
        const max = points.reduce((v, p) => Math.max(v, p.y), points[0].y)
        if (min === max) {
            this.chart.options.scales.y.min = min - 1
            this.chart.options.scales.y.max = max + 1
        } else {
            this.chart.options.scales.y.min = min - (max - min) * 0.2
            this.chart.options.scales.y.max = max + (max - min) * 0.2
        }
        this.chart.options.scales.x.min = points[0].x
        this.chart.options.scales.x.max = points[0].x + this.timeframe * 1000
        this.chart.update()
        this.chart.draw()
    }

    __getGradient(chart) {
        chart = chart != null ? chart : this.chart
        const { bottom } = chart.chartArea
        const { y } = chart.scales
        const gradientBorder = chart.ctx.createLinearGradient(0, 0, 0, bottom)
        if (!this.bet) {
            if (this.theme === 'light') {
                gradientBorder.addColorStop(0, 'rgba(66, 66, 66, 1)')
                gradientBorder.addColorStop(1, 'rgba(100, 100, 100, 1)')
            } else {
                gradientBorder.addColorStop(0, 'rgba(240, 240, 240, 1)')
                gradientBorder.addColorStop(1, 'rgba(200, 200, 200, 1)')
            }
        } else {
            let shift = y.getPixelForValue(this.bet.y) / bottom
            shift = Math.max(Math.min(shift, 1), 0)
            gradientBorder.addColorStop(0, 'rgba(0, 240, 194, 1)')
            gradientBorder.addColorStop(shift, 'rgba(0, 240, 194, 1)')
            gradientBorder.addColorStop(shift, 'rgba(255, 45, 116, 1)')
            gradientBorder.addColorStop(1, 'rgba(255, 45, 116, 1)')
        }
        return gradientBorder
    }

    __belowGradient() {
        const { bottom } = this.chart.chartArea
        const { y } = this.chart.scales
        let gradientBorder
        if (!this.bet) {
            return 'rgba(0, 0, 0, 0)'
        } else {
            gradientBorder = this.chart.ctx.createLinearGradient(0, y.getPixelForValue(this.bet.y), 0, bottom)
            gradientBorder.addColorStop(0, 'rgba(255, 45, 116, 0)')
            gradientBorder.addColorStop(1, 'rgba(255, 45, 116, 0.5)')
        }
        return gradientBorder
    }

    __aboveGradient() {
        const { top, bottom } = this.chart.chartArea
        const { y } = this.chart.scales
        let gradientBorder
        if (!this.bet) {
            gradientBorder = this.chart.ctx.createLinearGradient(0, bottom, 0, top)
            gradientBorder.addColorStop(0, 'rgba(128, 128, 128, 0)')
            gradientBorder.addColorStop(1, 'rgba(128, 128, 128, 0.3)')
        } else {
            gradientBorder = this.chart.ctx.createLinearGradient(0, y.getPixelForValue(this.bet.y), 0, top)
            gradientBorder.addColorStop(0, 'rgba(0, 240, 194, 0)')
            gradientBorder.addColorStop(1, 'rgba(0, 240, 194, 0.5)')
        }
        return gradientBorder
    }
}
