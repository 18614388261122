import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ethers } from 'ethers'
import { getAPY } from '../api/referal'
import { ApiResult } from '../../@types/ApiResponse'

export const initialState = {
    assetTokenPlusContractAddress: ethers.ZeroAddress,
    tokenPlusBalance: 0n,
    price: 1,
    apy: '0',
}
export const supplySlice = createSlice({
    name: 'supply',
    initialState,
    reducers: {
        setAssetTokenPlusContract(state, action: PayloadAction<string>) {
            state.assetTokenPlusContractAddress = action.payload
        },
        setTokenPlusBalance(state, action: PayloadAction<bigint>) {
            state.tokenPlusBalance = action.payload
        },
        setPrice(state, action: PayloadAction<number>) {
            state.price = action.payload
        },
    },
    extraReducers: {
        [getAPY.pending.type]: (state) => {
            //state.isLoading = true
        },
        [getAPY.fulfilled.type]: (state, action: PayloadAction<ApiResult<string>>) => {
            state.apy = action.payload.payload
        },
        [getAPY.rejected.type]: (state, action: PayloadAction<any>) => {
            //state.errors = [...action.payload.Errors]
            //state.isLoading = false
        },
    },
})

export default supplySlice.reducer
