import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { useUpdatePrices } from '../../../hooks/useUpdatePrices'
import { Left } from './main'
import { Timeframes } from './Timeframes'
import { getPoints } from '../../../store/api/prices'
import { allowedTimeframes } from '../../../lib/data'
//import './style.scss'
import { Chart } from '../../../components/BetChart/BetChartComponent'
import styled from 'styled-components'
import { gameSlice } from '../../../store/reducers/gameSlice'
import { BetFPCVm } from '../../../@types/Game/bet'
import CountUp from 'react-countup'

export const ChartBlock = () => {
    useUpdatePrices()

    const { currencyPrice, currencyPrevPrice, currency, timeframe, activeBet } = useAppSelector(
        (state) => state.gameSlice
    )
    const { themeName } = useAppSelector((state) => state.appSettings)
    const { points } = useAppSelector((state) => state.chartSlice)
    const { setTimeframe, setCurrencyPrice } = gameSlice.actions
    const dispatch = useAppDispatch()

    const chartRef = useRef<Chart>(null)

    useEffect(() => {
        dispatch(
            getPoints({
                currency: currency,
                timeframe: timeframe.value,
                pageNumber: 0,
                pageSize: 100,
            })
        )
    }, [currency, dispatch, timeframe.value])

    useEffect(() => {
        if (currencyPrice == 0 && points.pointsList?.length > 0) {
            dispatch(
                setCurrencyPrice({
                    value: points?.pointsList[0].value,
                    roundId: points?.pointsList[0].timeStamp,
                })
            )
        }
    }, [points])

    useEffect(() => {
        chartRef.current?.bet(activeBet)

        if (activeBet && (activeBet as BetFPCVm).betId != null) {
            const betTimeframe = allowedTimeframes.find(
                (timeframe) => timeframe.value === (activeBet as BetFPCVm).timeframe
            )
            dispatch(setTimeframe(betTimeframe!.name))
        }
    }, [chartRef, activeBet, dispatch, setTimeframe])

    const [diff, setdiff] = useState('')
    const [diffUp, setdiffUp] = useState(false)
    useEffect(() => {
        if (points.pointsList?.length > 0 && timeframe) {
            const pl = points.pointsList[points.pointsList.length - 1]
            let points_ = points.pointsList
                ?.map((x) => x)
                .sort((a, b) => {
                    let dateA = new Date(a.timeStamp)
                    let dateB = new Date(b.timeStamp)

                    if (dateA < dateB) {
                        return -1
                    }
                    if (dateA > dateB) {
                        return 1
                    }
                    return 0
                })
            if (activeBet && (activeBet as any).betId != null) {
                points_ = points.pointsList.filter((p) => {
                    if (
                        Date.parse(p.timeStamp) >= Date.parse((activeBet as any).lockedAt) &&
                        Date.parse(p.timeStamp) <= Date.parse((activeBet as any).lockedAt) + timeframe.value * 1000
                    ) {
                        return true
                    }
                    return false
                })
                points_.unshift({ timeStamp: (activeBet as any).lockedAt, value: (activeBet as any).lockPrice })
                points_.push(pl)
            } else {
                points_ = points.pointsList
                    .filter((p: any) => {
                        //p.x >= Date.now() - this.timeframe * 1000 - 60000 - this.options.pad
                        if (Date.parse(p.timeStamp) >= Date.now() - timeframe.value * 1000 - 60000 - 10000) {
                            return true
                        }
                        return false
                    })
                    .sort((a, b) => {
                        let dateA = new Date(a.timeStamp)
                        let dateB = new Date(b.timeStamp)

                        if (dateA < dateB) {
                            return 1
                        }
                        if (dateA > dateB) {
                            return -1
                        }
                        return 0
                    })
            }
            if (points_.length > 0) {
                const A = points_[0].value
                const B = points_[points_.length - 1].value
                if (A == B) {
                    setdiff('0%')
                    setdiffUp(true)
                } else {
                    setdiff(Math.abs(Math.round(((A - B) / B) * 1000000) / 10000) + '%')
                    setdiffUp(A - B >= 0)
                }
                //console.log({A})
                //console.log({ B })
                //console.log(points_)
            }
        }
    }, [activeBet, points, currencyPrice])
   
    return (
        <Left>
            <div className="left_row">
                <div style={{ display: 'flex' }}>
                    <CountUp
                        enableScrollSpy={true}
                        start={currencyPrevPrice}
                        end={currencyPrice}
                        duration={1.75}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix="$"
                    >
                        {({ countUpRef }) => <span className="cost" ref={countUpRef} />}
                    </CountUp>
                    {/*<StyledPercent up={diffUp!}><i></i>{diff}</StyledPercent>*/}
                </div>
                <Timeframes />
            </div>

            <StyledChart className="chart">
                {points.pointsList?.length > 0 && (
                    <Chart
                        ref={chartRef}
                        points={points.pointsList}
                        timeframe={timeframe.value}
                        theme={themeName}
                        priceCurrency="$"
                    />
                )}
            </StyledChart>
        </Left>
    )
}

const StyledChart = styled.div``
const StyledPercent = styled.div<{ up: boolean }>`
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: ${(props) => (props.up ? '#78f2c3' : '#ea4472')};

    i {
        width: 30px;
        height: 30px;
        mask-image: url(/images/earn/arrow.svg);
        mask-repeat: no-repeat;
        mask-size: 30px auto;
        mask-position: center;
        margin-left: -5px;
        background-color: ${(props) => (props.up ? '#78f2c3' : '#ea4472')};
        transform: ${(props) => (props.up ? 'translate(0, -3px) rotate(-90deg)' : 'translate(0, 3px) rotate(90deg)')};
    }
`
