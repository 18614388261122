import { Link } from 'react-router-dom'
import { StyledAbout, Headline } from './components/styles'
import { useAppSelector } from '../../hooks/redux'

const ContactPage = () => {
    const { themeSettings } = useAppSelector((state) => state.appSettings)
    return (
        <StyledAbout className="container">
            <div style={{ color: themeSettings.arrowBackgroundColor, width: '100%', paddingBottom: '10px' }}>
                If you’re experiencing issues:
            </div>
            <Headline> </Headline>

            <br />
            <div
                style={{
                    color: themeSettings.arrowBackgroundColor,
                    width: '100%',
                    paddingLeft: '30px',
                    paddingBottom: '10px',
                }}
            >
                1. First check the Help page for your error code
            </div>
            <br />
            <div
                style={{
                    color: themeSettings.arrowBackgroundColor,
                    width: '100%',
                    paddingLeft: '30px',
                    paddingBottom: '10px',
                }}
            >
                2. If you can’t find a solution, email us: 
                 <Link
                    style={{ color: themeSettings.arrowBackgroundColor }}
                    to="javascript:void(0)"
                    onClick={() =>
                        // @ts-ignore
                        (window.location = 'mailto:hi@borb.fi')
                    }
                >
                    hi@borb.fi
                </Link>
            </div>
            <div style={{ color: themeSettings.arrowBackgroundColor, width: '100%' }}>
                NEVER, under any situation, should you ever give someone your private key or recovery phrases.
            </div>
        </StyledAbout>
    )
}

export default ContactPage
