import React, { useState } from 'react'
import { Link } from 'react-scroll'

export const Navbar: React.FC = () => {
    const [activeLink, setActiveLink] = useState<string>('')

    const handleSetActive = (to: string) => {
        setActiveLink(to)
    }
    const SectionLink: React.FC<{ section: string; offset: number }> = ({ section, offset }) => {
        const normalized = section.toLowerCase().replaceAll(' ', '')
        return (
            <li>
                <Link
                    
                    className={`nav-link ${activeLink === normalized ? 'active' : ''}`}
                    to={normalized}
                    spy={true}
                    smooth={true}
                    offset={offset}
                    duration={500}
                    onSetActive={handleSetActive}
                >
                    {section}
                </Link>
            </li>
        )
    }
    return (
        <ul>
            <SectionLink section="Introduction" offset={0} />
            <SectionLink section="Getting started" offset={-4} />
            <SectionLink section="Setting up your Metamask" offset={5} />
            <SectionLink section="How to use Trade" offset={10} />
            <SectionLink section="Trade FAQ" offset={0} />
            <SectionLink section="How to use Supply" offset={0} />
            <SectionLink section="Supply FAQ" offset={0} />
            <SectionLink section="How to use Earn" offset={0} />
            <SectionLink section="Earn FAQ" offset={0} />
        </ul>
    )
}
