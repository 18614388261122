import { toast } from 'react-toastify'
import { FallbackProvider, JsonRpcProvider, JsonRpcSigner, ethers } from 'ethers'
import { ERC20__factory } from '../../@types/typechain-types'

export async function getBalance(assetAddress: string, address: string, web3Provider: FallbackProvider | JsonRpcProvider): Promise<bigint> {
    const assetContract = ERC20__factory.connect(assetAddress, web3Provider!)
    return await assetContract.balanceOf(address)
}

export async function isAllowed(
    address: string,
    assetAddress: string,
    web3Provider: JsonRpcSigner,
    amount: bigint,
    poolContractAddress: string
): Promise<boolean> {
    if (!!address && !!assetAddress) {
        try {
            const assetContract = ERC20__factory.connect(assetAddress, web3Provider!)
            const result = await assetContract.allowance(address, poolContractAddress)
            return result >= amount
        } catch (e: any) {
            console.log(e)
            toast.error(e.message)
        }
    }
    return false
}

export async function increaseAllowance(
    assetAddress: string,
    web3Provider: JsonRpcSigner,
    poolContractAddress: string
) {
    const assetContract = ERC20__factory.connect(assetAddress, web3Provider!)

    const result = await assetContract.connect(web3Provider!).approve(poolContractAddress, ethers.MaxUint256)

    toast.promise(result.wait(), {
        pending: 'Wait for transaction...',
        success: 'Allowance increased 👌',
        error: 'Error 🤯',
    })
}
