import { JsonRpcSigner, ethers } from 'ethers'
import { toast } from 'react-toastify'
import { ERC20__factory } from '../@types/typechain-types'

export function getQueryVariable(variable: string) {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
            return pair[1]
        }
    }
    return false
}

export const addressToPointsFormat = (address: string, firstPartTo: number, secondPartFrom: number): string => {
    return `${address.slice(0, firstPartTo)}....${address.slice(secondPartFrom)}`
}

export async function isAllowed(
    address: string,
    tokenAddress: string,
    web3Provider: JsonRpcSigner,
    contractAddress: string
): Promise<boolean> {
    if (!!address && !!tokenAddress) {
        try {
            const assetContract = ERC20__factory.connect(tokenAddress, web3Provider!)
            const result = await assetContract.allowance(address, contractAddress)
            return result != 0n
        } catch (e: any) {
            console.log(e)
            toast.error(e.message)
        }
    }
    return false
}

export async function increaseAllowance(nftAddress: string, signer: JsonRpcSigner, operatorContractAddress: string) {
    const nftContract = ERC20__factory.connect(nftAddress, signer)

    const result = await nftContract.connect(signer).approve(operatorContractAddress, ethers.MaxUint256)

    toast.promise(result.wait(), {
        pending: 'Wait for transaction...',
        success: 'Allowance increased 👌',
        error: 'Error 🤯',
    })
}

export async function switchNetwork() {
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [
                {
                    chainId: ethers.toQuantity(ethers.toBeHex(Number.parseInt(import.meta.env.VITE_APP_CHAIN_ID!))),
                },
            ],
        })
    } catch (e: any) {
        console.error(e);
        //toast.error(e)
    }
}

export async function addToMM(address: string, symbol: string) {
    try {
        //@ts-ignore
        await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: address,
                    symbol: symbol,
                    decimals: 18,
                    //image: 'https://example.com/token-image.png', // Replace with the URL of the token's image
                },
            },
        })
    } catch (e) {
        console.log('connect error', e)
    }
}