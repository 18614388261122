import { useTranslation } from 'react-i18next'
import coinbase_Logo from '../../../assets/coinbase_Logo.png'
import metamask_Logo from '../../../assets/metamask_Logo.svg'
import binance_Logo from '../../../assets/binance.png'
import { isMetamask, isMobile } from '../../../constants/browsers'
import CloseButton from '../CloseBtn/CloseButton'
import ConnectButton from '../ConnectButton/ConnectButton'
import styles from './ConnectModal.module.scss'
import { useAccount, useConnect } from 'wagmi'
import { toast } from 'react-toastify'
import { switchNetwork } from '../../../utils/sharedFunctions'

interface ConnectModalProps {
    isModalOpen: boolean
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ConnectModal: React.FC<ConnectModalProps> = ({ isModalOpen, setIsModalOpen }) => {
    const { t } = useTranslation()
    const { address } = useAccount()
    function isMobileDevice() {
        return 'ontouchstart' in window || 'onmsgesturechange' in window
    }

    const { connect, connectors } = useConnect()

    return (
        <>
            {isModalOpen && (
                <div className={styles.overlay}>
                    <div className={styles.connectModalWindow}>
                        <div>
                            <div className={styles.popupHeader}>
                                <div className={styles.modalTitle}>{t('Connect Wallet')}</div>
                                <CloseButton onClose={() => setIsModalOpen(false)} />
                            </div>
                            <div className={styles.walletList}>
                                {isMobile && !isMetamask ? (
                                    <a href={`https://metamask.app.link/dapp/${window.location.href}`}>
                                        <ConnectButton
                                            label="MetaMask"
                                            image={metamask_Logo}
                                            onClick={async () => {
                                                await switchNetwork()
                                                connect({
                                                    connector: connectors.find(
                                                        (x) => x.name.toLowerCase() == 'metamask'
                                                    ),
                                                })
                                                toast.success('Connected to Web3')
                                            }}
                                        />
                                    </a>
                                ) : (
                                    <ConnectButton
                                        label="MetaMask"
                                        image={metamask_Logo}
                                        onClick={async () => {
                                            await switchNetwork()
                                            connect({
                                                connector: connectors.find((x) => x.name.toLowerCase() == 'metamask'),
                                            })
                                            toast.success('Connected to Web3')
                                        }}
                                    />
                                )}
                                <ConnectButton
                                    label="Coinbase Wallet"
                                    image={coinbase_Logo}
                                    onClick={async () => {
                                        await switchNetwork()
                                        connect({
                                            connector: connectors.find((x) => x.name.toLowerCase() == 'metamask'),
                                        })
                                        toast.success('Connected to Web3')
                                    }}
                                />
                                <ConnectButton
                                    label="Binance Wallet"
                                    image={binance_Logo}
                                    onClick={async () => {
                                        await switchNetwork()
                                        connect({
                                            connector: connectors.find((x) => x.name.toLowerCase() == 'binance'),
                                        })
                                        toast.success('Connected to Web3')
                                    }}
                                />
                                <div
                                    style={{
                                        margin: 'auto',
                                        fontSize: '15px',
                                        marginBottom: '0',
                                        marginTop: '12px',
                                    }}
                                >
                                    {t('Need help installing a wallet?')}
                                    <a
                                        href="https://metamask.zendesk.com/hc/en-us/articles/360015489471-How-to-Install-MetaMask-Manually"
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        {t('Click here')}
                                    </a>
                                </div>

                                <div
                                    style={{
                                        margin: 'auto',
                                        fontSize: '10px',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        paddingBottom: '20px',
                                    }}
                                >
                                    {t('Wallets are provided by External Providers')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ConnectModal
