import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { gameSlice } from '../../../store/reducers/gameSlice'
import { InputWrapper } from '../../SupplyPage'
import { Button, Buttons, Card, Counter, Grid, HelperRow, Right, Text } from './main'
import { toast } from 'react-toastify'
import { ChangeEvent, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { SelectAsset } from '../../../components/SelectAsset/SelectAsset'
import { getBalance, increaseAllowance, isAllowed } from '../../../store/api/contracts'
import { useTranslation } from 'react-i18next'
import CurrencyInput from 'react-currency-input-field'
import { useAccount } from 'wagmi'
import { useEthersProvider } from '../../../hooks/useEthersProvider'
import { useEthersSigner } from '../../../hooks/useEthersSigner'
import { Borb, Borb__factory, Pool, Pool__factory } from '../../../@types/typechain-types'

const BetTypeUP = 0
const BetTypeDown = 1
export type BetType = 0 | 1

const ERROR_CODE_TX_REJECTED_BY_USER = 4001

export function BetCard() {
    const { t } = useTranslation()
    const { address } = useAccount()
    const provider = useEthersProvider()
    const signer = useEthersSigner()
    const {
        ref,
        asset,
        //rewardPercent,
        gameFPCContractAddress,
        assetContractAddress,
        poolContractAddress,
        userBalance,
        timeframe,
        currency,
    } = useAppSelector((state) => state.gameSlice)
    const { setAssetContract, setUserBalance, setRewardPercent, setActiveBet } = gameSlice.actions
    const dispatch = useAppDispatch()
    const [amount, setAmount] = useState<string>('0')
    const [reload, setReload] = useState<number>(0)
    const [maxBet, setMaxBet] = useState<bigint>(0n)
    const [gameContract, setGameContract] = useState<Borb>()
    const [poolContract, setPoolContract] = useState<Pool>()
    const [rewardPercent, setrewardPercent] = useState(80)
    useEffect(() => {
        dispatch(setActiveBet({}))
    }, [])

    useEffect(() => {
        if (!provider) return
        setGameContract(Borb__factory.connect(gameFPCContractAddress, provider!))
        setPoolContract(Pool__factory.connect(poolContractAddress, provider!))

        //логика отлова события через провайдер кошелька. получается дольше, чем с бэкэнда
        //gameContract.on('NewBetAdded', (args) => {
        //alert('event')
        //console.log({ args })
        //})
        /*gameContract.on('Error', (error, receipt) => {
            //setError(error.reason)
            alert('reson' + error.reason)
            console.log({ error })
        })*/
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, provider])

    useEffect(() => {
        // Using an IIFE
        ;(async function anyNameFunction() {
            if (!provider || !gameContract || !poolContract) return
            try {
                const assetAddress = await poolContract!.connect(provider).getAssetAddress(asset.name)
                dispatch(setAssetContract(assetAddress))
                if (assetAddress !== ethers.ZeroAddress && !!address) {
                    const userBalance = await getBalance(assetAddress!, address!, provider)
                    dispatch(setUserBalance(userBalance))
                    //console.log(`${gameContract.address}: curid:${currency.id}; assetid:${asset.id}; tfv:${timeframe.value}`)
                    /*const rewardPercent = await gameContract
                        .connect(provider)
                        .rewardPercent(currency.id, asset.id, timeframe.value)*/
                    dispatch(setRewardPercent(+rewardPercent.toString()))
                    setMaxBet(await gameContract.connect(provider).maxBetAmount())
                }
            } catch (e: any) {
                console.error(e)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset, address, reload, gameContract, timeframe])

    async function setBetHandler(percent: bigint) {
        let newBetValue = userBalance > maxBet ? maxBet : userBalance
        if (percent !== 0n) {
            newBetValue = (userBalance / 100n) * percent
        }
        setAmount(Number(ethers.formatUnits(newBetValue,18)).toFixed(2))
    }

    async function makeBetHandler(betType: BetType) {
        if (!address) {
            toast.info(t('HomePage.Connect wallet'))
        }
        const formattedAmount = ethers.parseUnits(amount.toString(),18)
        if (formattedAmount == 0n) {
            toast.info('Amount must be greater than 0')
            return
        }
        try {
            const rewardTokenAddress = await poolContract?.connect(provider).getRewardTokenAddress(asset.name)
            if (!(await isAllowed(address!, assetContractAddress!, signer!, formattedAmount, rewardTokenAddress!))) {
                await increaseAllowance(assetContractAddress, signer!, rewardTokenAddress!)
            }
            //check balances
            const potentialReward =
                (await gameContract?.getReward(asset.id, currency.id, timeframe.value, formattedAmount)) ?? 0
            const poolBalanceEnought = await poolContract?.poolBalanceEnough(potentialReward, asset.id)
            if (!poolBalanceEnought) {
                toast.warning(t('HomePage.Pool Balance Not Enought'))
                return
            }
            const userBalanceEnought = await poolContract?.userBalanceEnough(address!, formattedAmount, asset.id)
            if (!userBalanceEnought) {
                toast.warning(t('HomePage.User Balance Not Enought'))
                return
            }

            if (formattedAmount < (await gameContract!.minBetAmount())) {
                toast.warning(t('HomePage.User Bet too low'))
                return
            }
            if (formattedAmount > (await gameContract!.maxBetAmount())) {
                toast.warning(t('HomePage.User Bet too high'))
                return
            }

            const fee = await gameContract!.calculatorFee()

            const result = await gameContract!.connect(signer!).makeBet(
                formattedAmount,
                ref,
                timeframe.value,
                asset.id,
                betType,
                currency.id,
                { value: fee /*, gasLimit: 3000000*/ } //todo убрать gaslimit
            )
            toast
                .promise(result.wait(), {
                    pending: t<string>('HomePage.Adding bet'),
                    success: t<string>('HomePage.Bet added 👌'),
                    error: t<string>('HomePage.Error 🤯'),
                })
                .then((_) => setReload((prev) => prev + 1))
        } catch (e: any) {
            if (e.reason=== "rejected") {
                toast.info('TX_REJECTED_BY_USER')
                return
            } else {
                toast.error(e.message)
            }
        }
    }
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let newValue = event.target.value.replaceAll(',', '')
        if (newValue[newValue.length - 1] === '.') {
            newValue += '0'
        }
        if (Number.isNaN(Number.parseFloat(newValue))) {
            newValue = '0'
        }
        setAmount(Number.parseFloat(newValue).toString())
    }
    return (
        <Right>
            <div className="content">
                <Counter>{rewardPercent}%</Counter>
                <InputWrapper>
                    <div className="input-wrapper">
                        <SelectAsset />
                        <CurrencyInput
                            type="text"
                            groupSeparator=","
                            decimalSeparator="."
                            className="input"
                            placeholder={t<string>('HomePage.Amount')}
                            value={amount}
                            onChange={handleChange}
                            allowDecimals
                            decimalsLimit={2}
                            disabled={!address}
                        />
                    </div>
                </InputWrapper>
                <HelperRow>
                    <Text onClick={() => setBetHandler(100n)} style={{cursor:"pointer"}}>
                        {t('HomePage.Balance')}: {Number(ethers.formatUnits(userBalance,18)).toFixed(2)}
                    </Text>
                    <Text>
                        ${(Number.parseFloat(amount) + (Number.parseFloat(amount) / 100) * rewardPercent).toFixed(2)}{' '}
                        {t('HomePage.payout')}
                    </Text>
                </HelperRow>
                <Grid>
                    {/*<Card onClick={() => setBetHandler(10n)}>10%</Card>
                    <Card onClick={() => setBetHandler(25n)}>25%</Card>
                    <Card onClick={() => setBetHandler(50n)}>50%</Card>
                    <Card onClick={() => setBetHandler(75n)}>75%</Card>
                    <Card onClick={() => setBetHandler(100n)}>Max</Card>*/}
                </Grid>
                <Buttons>
                    <Button onClick={() => makeBetHandler(BetTypeUP)}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 12L22 20H10L16 12Z" fill="#238069" />
                        </svg>
                    </Button>
                    <Button red onClick={() => makeBetHandler(BetTypeDown)}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 20L10 12L22 12L16 20Z" fill="#8C3045" />
                        </svg>
                    </Button>
                </Buttons>
            </div>
        </Right>
    )
}
