import styled from 'styled-components'

const CFF = () => {
    return (
        <StyledCFF>
            <div className="container">
                <Text>
                    Contracts for five coming soon.
                </Text>
            </div>
        </StyledCFF>
    )
}

export default CFF

const StyledCFF = styled.div`
    p {
        color: ${(props) => props.theme.titleColor};
    }
`
const Text = styled.p`
    color: ${(props) => props.theme.titleColor};
`
