import * as React from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { allowedAssets } from '../../lib/data'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { gameSlice } from '../../store/reducers/gameSlice'
import { ERC20__factory, Pool__factory } from '../../@types/typechain-types'
import { useEthersProvider } from '../../hooks/useEthersProvider'
import { useAccount } from 'wagmi'
import { getBalance } from '../../store/api/contracts'

interface ISelectAssetProps {
    addPlus?: boolean
    notAllowedAsset?:string
}

export const SelectAsset: React.FunctionComponent<ISelectAssetProps> = ({ addPlus, notAllowedAsset }) => {
    const { address } = useAccount()
    const provider = useEthersProvider()
    const { asset, poolContractAddress } = useAppSelector((state) => state.gameSlice)
    const { setUserBalance, setAsset } = gameSlice.actions
    const dispatch = useAppDispatch()
    const [popup, setPopup] = React.useState<boolean>(false)
    let ref2 = React.useRef(null)
    useOnClickOutside(ref2, () => setPopup(false))

    /*React.useEffect(() => {
        // Using an IIFE
        ;(async function anyNameFunction() {
            if (!provider || !address) return
            try {
                const pool = Pool__factory.connect(poolContractAddress, provider!)
                const assetAddress = await pool.getAssetAddress(asset.name)
                const assetContract = ERC20__factory.connect(assetAddress, provider!)

                const filter = assetContract.filters.Transfer(address)
                assetContract.on(filter, (from, to, amount, event) => {
                    console.log('event listener')
                    if (from == address || to == address) {
                        getBalance(assetAddress!, address!, provider).then((b) => dispatch(setUserBalance(b)))
                    }
                })
            } catch (e: any) {
                console.error(e)
            }
        })()
    }, [asset, address])*/

    return (
        <>
            <SelectWrapper
                onClick={() => {
                    setPopup(true)
                }}
            >
                <CurrencyWrapper>
                    <img src={asset.img} alt={asset.name} />
                </CurrencyWrapper>
                <span>
                    {asset.name}
                    {addPlus && '+'}
                </span>
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="arrow"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M8 10L5 6L11 6L8 10Z" fill="#23272B" />
                </svg>
            </SelectWrapper>
            {popup && (
                <SelectBody ref={ref2}>
                    {allowedAssets
                        .filter((x) => x.name != notAllowedAsset)
                        .map((item, _idx) => (
                            <div
                                key={_idx}
                                className="select_card"
                                onClick={() => {
                                    dispatch(setAsset(item.name))
                                    setPopup(false)
                                }}
                            >
                                <img src={item.img} alt={item.name} />
                                <p>
                                    {item.name}
                                    {addPlus && '+'}
                                </p>
                            </div>
                        ))}
                </SelectBody>
            )}
        </>
    )
}

export const CurrencyWrapper = styled.div`
    margin-right: 4px;
    display: flex;
    justify-content: center;
`
export const SelectWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    .colored {
        fill: #fff;
    }
    span {
        font-family: 'Inter';
        font-weight: 400;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 140%;
        color: ${(props) => props.theme.selectColor};
    }
    path {
        fill: ${(props) => props.theme.arrowBackgroundColor};
    }
`

export const SelectBody = styled.div`
    position: absolute;
    z-index: 111;
    left: 8px;
    top: 45px;
    width: 150px;
    padding: 16px 0;
    background: ${(props) => props.theme.navbarBg};
    border-radius: 8px;
    box-shadow: 0 5px 20px rgb(0 0 0 / 25%);
    .select_card {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        cursor: pointer;

        img {
            width: 20px;
            height: 20px;
        }

        p {
            color: ${(props) => props.theme.arrowBackgroundColor};
            margin-left: 16px;
            text-transform: uppercase;
            font-size: 16px;
        }
        &:hover {
            background: ${(props) => props.theme.selectColor};
            p {
                color: ${(props) => props.theme.navbarBg};
            }
        }
    }
`
