import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Footer = () => {
    const { t } = useTranslation()

    return (
        <StyledFooter>
            <div className="icons">
                <a href="https://twitter.com/borb_fi" target="_blank" rel="noreferrer">
                    <img src="/images/primary/twi.svg" alt="" />
                </a>
                <a href="https://github.com/orgs/borb-fi/" target="_blank" rel="noreferrer">
                    <img src="/images/primary/github.svg" alt="" />
                </a>
                <a href="https://www.reddit.com/r/BorB_fi/" target="_blank" rel="noreferrer">
                    <img src="/images/primary/redit.svg" alt="" />
                </a>
                <a href="https://t.me/borb_fi" target="_blank">
                    <img src="/images/primary/tg.svg" alt="Telegram" />
                </a>
            </div>
            <p>© {new Date().getFullYear()} BorB</p>
        </StyledFooter>
    )
}

export default Footer

const StyledFooter = styled.footer`
    padding: 0 40px;
    margin-top: auto;
    height: 72px;
    display: none;
    align-items: center;
    box-shadow: 0px -1px 0px ${(props) => props.theme.borderColor};
    background: ${(props) => props.theme.backgroundContent};
    p {
        color: var(--grey80);
        margin-left: 24px;
        font-size: 12px;
    }
    .icons a {
        cursor: pointer;
        &:not(:last-child) {
            margin-right: 16px;
        }
    }
    @media screen and (max-width: 1280px) {
        display: flex;
    }
    @media screen and (max-width: 1000px) {
        padding: 0 16px;
    }
    @media screen and (max-width: 480px) {
        padding: 24px 16px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
            width: 32px;
            height: 32px;
        }

        .icons { 
            margin-bottom: 16px;
        }
    }
`
