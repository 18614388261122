import { Faq } from '../../components/Faq'
import { Navbar } from './components/Navbar/Navbar'
import { StyledAbout, Text, Title, Headline, SubTitle, Small, AboutLink } from './components/styles'

const AboutPage = () => {
    const DATA = [
        {
            title: 'FAQ.Question 1.Question',
            subtitle: 'FAQ.Question 1.Answer',
        },
        {
            title: 'FAQ.Question 2.Question',
            subtitle: 'FAQ.Question 2.Answer',
        },
        {
            title: 'FAQ.Question 3.Question',
            subtitle: 'FAQ.Question 3.Answer',
        },
        {
            title: 'FAQ.Question 4.Question',
            subtitle: 'FAQ.Question 4.Answer',
        },
        {
            title: 'FAQ.Question 5.Question',
            subtitle: 'FAQ.Question 5.Answer',
        },
        {
            title: 'FAQ.Question 6.Question',
            subtitle: 'FAQ.Question 6.Answer',
        },
        {
            title: 'FAQ.Question 7.Question',
            subtitle: 'FAQ.Question 7.Answer',
        },
        {
            title: 'FAQ.Question 8.Question',
            subtitle: 'FAQ.Question 8.Answer',
        },
        {
            title: 'FAQ.Question 9.Question',
            subtitle: 'FAQ.Question 9.Answer',
        },
        {
            title: 'FAQ.Question 10.Question',
            subtitle: 'FAQ.Question 10.Answer',
        },
        {
            title: 'FAQ.Question 11.Question',
            subtitle: 'FAQ.Question 11.Answer',
        },
        {
            title: 'FAQ.Question 12.Question',
            subtitle: 'FAQ.Question 12.Answer',
        },
        {
            title: 'FAQ.Question 13.Question',
            subtitle: 'FAQ.Question 13.Answer',
        },
        {
            title: 'FAQ.Question 14.Question',
            subtitle: 'FAQ.Question 14.Answer',
        },
        {
            title: 'FAQ.Question 15.Question',
            subtitle: 'FAQ.Question 15.Answer',
        },
    ]
    const supdata = [
        {
            title: 'What is Tokens+?',
            subtitle: `Token+ is a LP token that you receive when you supply USDT or USDC to the BorB protocol. These LP tokens represent your stablecoins in BorB's
liquidity pool. They act as a receipt, allowing you to exchange them later on
for your supplied stablecoins and the earned rewards.`,
        },
        {
            title: 'What’s the Token+ contract address? ',
            subtitle: `Verified USDT+ contract address: 0x048815b747EA25AD4B28Af53aeE715dFCa8Da6bd
            <br/>
            <br/>
                       Verified USDC+ contract address: 0xA76786Acf22F30EBe47733AacD17273a7B0e6790`,
        },
        {
            title: 'Do I need to claim my rewards?',
            subtitle: `No. Token+ is a frictionless token. This means that each day, its value is
recalculated. Rewards are earned based off the stablecoins supplied in BorB.
The price of Token+ is calculated as follows: No. Token+ is a rewards-accruing token. This means that each day its value is recalculated based on the rewards earned by the BorB LP pool. The price of Token+ is calculated as follows: Price of Token+ = total_supplied/tokens_minted `,
        },
        {
            title: 'Can you walk me through an example?',
            subtitle: `Let’s say you supply 1,000 USDC to the BorB protocol, when the exchange
rate is 2.5; you would receive 400 USDC+ (1,000/2.5).
A few months later, you decide it’s time to withdraw your USDC from the
protocol; the exchange rate is now 2.8:
<br/>
- Your 1,000 USDC+ is now equal to 1,120 USDC (400 * 2.8)
<br/>
- You could withdraw 1,120 USDC, which would redeem all 400
USDC+
<br/>
- Or, you could withdraw a portion, such as your original 1,000 USDC, which
would redeem 357.15 USDC+ (keeping 42.85 USDC+ in your wallet)`,
        },
        {
            title: 'It is possible to see any loses? ',
            subtitle: `No, because the price of Token+ can only go up. Technically it does not have the ability to decrease. Therefore, you can always withdraw exactly your amount or more.
`,
        },
    ]
    const earndata = [
        {
            title: "What is BorB's referral program? ",
            subtitle: `BorB referral program opens a way for protocols, websites, wallets and
individuals to earn $tablecoins, by promoting prediction market dApp. `,
        },
        {
            title: 'How the referral program works?',
            subtitle: `- After logging in to the wallet, copy the link
            <br/>
- Share the link
<br/>
- Receive payments from incoming users `,
        },
        {
            title: 'Who can use the referral program? ',
            subtitle: `BorB's referral program is open to anyone. If you have an audience and
bring it to use BorB, the referral program will allow you to benefit from it. `,
        },
        {
            title: 'How do I get paid? ',
            subtitle: `Whenever someone uses your referral to trade on BorB, the fee paid
during the transaction will be sent to the wallet you provided. `,
        },
        {
            title: 'How much can I earn? ',
            subtitle: `It depends on the activity of your referrals - but we can provide you with
50% of the protocol commissions. Lifetime. `,
        },
        {
            title: 'Is the number of referrals limited?',
            subtitle: 'No, you can invite any number of traders.',
        },
    ]
    return (
        <StyledAbout className="container">
            <div className="first-column">
                <Title>Guides</Title>
                <div id="introduction">
                    <Headline>Introduction</Headline>
                    <Text>
                        BorB is a decentralized prediction market platform built on blockchain (currently operating on
                        BNB Smart Chain. Ethereum and Polygon coming soon). The platform’s core enabling technology
                        provides the ability to settle markets within seconds on the blockchain. BorB lets you test your
                        foresight to earn rewards. Use your market knowledge or gut feeling to predict if the BTC or
                        other asset will go up or down in the near future. Get it right and you’ll earn up to 80% from
                        your deposit. These are ‘Bullish’ or ‘Bearish’ bets, allowing the trader to get a result in just
                        a matter of a few minutes.
                    </Text>
                    <SubTitle></SubTitle>
                </div>
                <div id="gettingstarted">
                    <Headline>Getting started</Headline>
                    <Text>
                        First of all, BorB is a fully decentralized Web3 application. Web3 is an umbrella term for the
                        vision of a better internet; an internet with an added identity, money, and social layer. An
                        internet built upon open protocols that value transparency and innovation. Web3 takes away the
                        need for intermediaries or third parties and gives you direct ownership over your digital
                        identity and assets in a way like never before. It is the shift from an internet of information,
                        to an internet of value. BorB just has a “Connect Wallet” button that acts as a sign-in. It’s
                        open for all to use, regardless of where you are located or what industry you hail from. Using
                        anything new can be a bit of a challenge. Don't worry though, we've created a range of guides to
                        help you get comfortable with BorB!
                    </Text>
                    <SubTitle></SubTitle>
                </div>
                <div id="settingupyourmetamask">
                    <Headline>Setting up your Metamask</Headline>
                    <Text>
                        <SubTitle>Step 1: Install MetaMask</SubTitle>
                        Official website: https://metamask.io/ <br />
                        Type: Web browser, Android Android, Apple iOS <br />
                        Supported browsers: Chrome (Google) browser, Firefox (Firefox) browser, Brave browser, Microsoft
                        Edge browser.
                        <br />
                        Wallet download address https://metamask.io/download.html
                        <br />
                        <img
                            height="400rem"
                            src="https://2207347562-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F9ZIO38hs5dVeDYMAZzYJ%2Fuploads%2FqJMCAVQmFPL4ILSwT4CS%2FMetamask1.png?alt=media&token=04ed8ad9-990e-4eb1-a95f-53c8571b6b5f"
                        />
                        <br />
                        If you do not have MetaMask, we recommend that you install and use it in browsers such as Chrome
                        and Brave. Note that it is best to use the official method for installation, otherwise digital
                        assets may be stolen. <br />
                        <br />
                        1. Click to jump to the installation page: <br />
                        <br />
                        For example, if you install Google Chrome, click Install MetaMask for Chrome.
                        <br />
                        <br />
                        2. Jump to chrome store: <br />
                        <br />
                        Click add to chrome and a fox icon will appear in the upper right corner of your browser.
                        <br />
                        <img
                            height="150rem"
                            src="https://2207347562-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F9ZIO38hs5dVeDYMAZzYJ%2Fuploads%2FKFm4oQP4KXYpuC1JwSkP%2FMetamask2.png?alt=media&token=51bdc1af-3902-4af4-b0a2-d37c1691c856"
                        />
                        <SubTitle>Step 2: Initial Setup MetaMask</SubTitle>
                        <br />
                        1. Open after installation
                        <br />
                        <br />
                        The browser has successfully installed the MetaMask extension. After that, there will be a fox
                        icon on the extension program, click to open.
                        <br />
                        <img
                            height="450rem"
                            src="https://2207347562-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F9ZIO38hs5dVeDYMAZzYJ%2Fuploads%2FqQUiNPwFa7EnTwjWD13g%2FMetamask3.png?alt=media&token=85386dbd-8614-4cba-8793-9683fb4fb20f"
                        />
                        <br />
                        If the browser does not display by default, click [Extension] (puzzle fragment icon), and click
                        the icon behind MetaMask [Thumbnail icon]
                        <br />
                        <br />
                        2. Wallet creation or import
                        <br />
                        <br />
                        Users who use MetaMask for the first time must import or create a new wallet. Users who already
                        have a wallet can click "import wallet" to import the wallet through mnemonic words. New users
                        can create a new wallet.
                        <br />
                        <img
                            height="350rem"
                            src="https://2207347562-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F9ZIO38hs5dVeDYMAZzYJ%2Fuploads%2FOQgSllvzcbuTmJjiMKWq%2FMetamask4.png?alt=media&token=cd8016a8-2387-4d53-8011-3043685c50f7"
                        />
                        <br />
                        3. Import wallet <br />
                        <br />
                        You need to enter a mnemonic phrase, a new password, and accept the terms of use.
                        <br />
                        <img
                            height="450rem"
                            src="https://2207347562-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F9ZIO38hs5dVeDYMAZzYJ%2Fuploads%2FlHtjw2IloaECESQktque%2FMetamask5.png?alt=media&token=d242b5b8-54d8-4021-8fb6-4940a8009025"
                        />
                        <br />
                        On the next page, your wallet mnemonic words will be displayed. (Ciphertext) Please back these
                        mnemonic words and do not disclose them to others or upload them to the Internet. Once the
                        mnemonic word are disclosed, your digital assets are in danger of permanent loss.
                        <br />
                        <br />
                        4. Confirm mnemonic phrase
                        <br />
                        <img
                            height="450rem"
                            src="https://2207347562-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F9ZIO38hs5dVeDYMAZzYJ%2Fuploads%2FLrbGu5BNumI4zgsdu5H1%2FMetamask6.png?alt=media&token=907f2c14-4a32-4880-9ceb-ef79e641144e"
                        />
                        <br />
                        On this page, you enter your mnemonic phrase in the correct order to confirm.
                        <br />
                        <br />
                        Finally you can connect your MetaMask wallet to BorB and enjoy predicting the markets.
                    </Text>
                    <SubTitle></SubTitle>
                </div>
                <div id="howtousetrade">
                    <Headline>How to use Trade</Headline>
                    <Text>
                        Starting is simple! Take for example the BTC Market. <br />
                        <br />
                        <SubTitle>Step 1</SubTitle> Select a timeframe of 5 min, 15 min, 1 hour, 4 hours or 24 hours{' '}
                        <br />
                        <SubTitle>Step 2</SubTitle> Enter amount from $1 to $1000 in USDC or USDT <br />
                        <SubTitle>Step 3</SubTitle> Go up or down
                    </Text>
                    <SubTitle></SubTitle>
                </div>
                <div id="tradefaq">
                    <Headline>Trade FAQ </Headline>
                    <Faq data={DATA} />
                    <SubTitle></SubTitle>
                </div>
                <div id="howtousesupply">
                    <Headline>How to use Supply</Headline>
                    <Text>
                        Supply liquidity to prediction market dApp with high APY in stablecoins. The smart-contracts,
                        making the settlements of the participants are opensource and available on the GitHub of the
                        project for the community review. Liquidity on BorB is provided by a decentralized LP pools. The
                        profitability of LP pools should be 2-4%. In this case, successful traders take money from
                        unsuccessful ones, and liquidity providers earn on volumes. In order to enable decentralized LP
                        pools, LP providers will have to acquire USDC or USDT and deposit it in BorB's smart contract.
                        This mechanism was primarily introduced to resolve issues around in-game liquidity. Providing
                        stablecoins gives the LP right to participate in LP pools and capture fees generated from the
                        platform. When participants provide liquidity, BorB LP share tokens (Token+) are issued. Token+
                        holders can withdraw at any time to receive their share of the pool, which includes any gains.
                    </Text>
                    <SubTitle></SubTitle>
                </div>
                <div id="supplyfaq">
                    <Headline>Supply FAQ</Headline>
                    <Faq data={supdata} />
                    <SubTitle></SubTitle>
                </div>
                <div id="howtouseearn">
                    <Headline>How to use Earn</Headline>
                    <Text>
                        BorB blockchain-based referral program, is a system of a smart-contracts that settle the split
                        of commissions from the trade volumes on the protocol to referrals (protocol commission 50%,
                        referral commission - 50%). Every blockchain wallet is connected to the unique referral link,
                        allowing referrals to utilize the traffic and influence to the real money, instantly, on BorB
                        prediction market protocol. The smart-contracts, making the settlements of the participants are
                        opensource and available on the GitHub of the project for the community review. Now the solution
                        is implemented on BNB Smart Chain (Polygon and Ethereum coming soon). BorB is working on
                        interoperability features for the referrals. (For example, if the link was provided on BNB Smart
                        Chain, but the trader decided to trade on BNB Smart Chain, so the referral should still be able
                        to receive the commission anywhere).
                    </Text>
                    <SubTitle></SubTitle>
                </div>
                <div id="earnfaq">
                    <Headline>Earn FAQ</Headline>
                    <Faq data={earndata} />
                    <br />
                    <br />
                    <br />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
            <div className="second-column">
                <Navbar />
            </div>
        </StyledAbout>
    )
}

export default AboutPage
