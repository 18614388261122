import { StyledAbout, Text, Title, Headline, SubTitle, Small, AboutLink } from './components/styles'

const AboutPage = () => {
    return (
        <StyledAbout className="container">
            <div>
                <Title>Docs</Title>
                <Headline id="introduction">Terms of Service</Headline>
                <Text>
                    Welcome to borb.fi (“BorB”, “we”, “our”, or “us”). BorB is a group of early prediction market
                    researchers who are committed to furthering the adoption, research and academic discoveries within
                    predictive forecasting. BorB operates educational and user resources for existing and promising
                    applications of predictive software.
                    <br />
                    This page explains the terms by which you may use our website (the “Site”). By accessing or using
                    the Site, you signify that you have read, understood, and agree to be bound by this Terms of Service
                    Agreement (“Agreement”), whether or not you are a registered user of our Site. BorB reserves the
                    right to make unilateral modifications to these terms and will provide notice of these changes as
                    described below. This Agreement applies to all visitors, users, and others who access the Site
                    (“Users”).
                    <br />
                    Please read this Agreement carefully to ensure that you understand each provision. This agreement
                    contains a mandatory individual arbitration and class action/jury trial waiver provision that
                    requires the use of arbitration on an individual basis to resolve disputes, rather than jury trials
                    or class actions.
                    <br />
                </Text>
                <SubTitle>BorB Site</SubTitle>
                <Text>
                    As part of the Site, BorB provides access to a decentralized prediction-market and hedging tool
                    (“Protocol”) on the Ethereum, Polygon and Polygon blockchain, that allows participants to use
                    Ethereum, Polygon and Polygon assets (“Assets”) to transact using smart contracts (“Smart
                    Contracts”). BorB does not have any role in the operation of markets created on Protocol, and do not
                    have the ability to spend funds that are held in escrow on-contract, do not control how markets
                    resolve or are created, do not approve or reject trades or other transactions on the network, and do
                    not have the ability to modify, cancel, undo, or interact with orders on the network. BorB have no
                    power to censor, restrict, or curate markets, orders, trades, positions or resolutions on the Smart
                    Contracts. BorB have no more control over the Protocol than anyone else using Ethereum, Polygon or
                    BNB Smart Chain.
                </Text>
                <SubTitle>Use of Our Site</SubTitle>
                <Text>
                    Eligibility This is a contract between you and BorB. You must read and agree to these terms before
                    using the Site. If you do not agree, you may not use the Site. You may use the Site only if you can
                    form a binding contract with BorB, and only in compliance with this Agreement and all applicable
                    local, state, national, and international laws, rules and regulations. Any use or access to the Site
                    by anyone under eighteen (18) years of age is strictly prohibited and in violation of this
                    Agreement. The Site is not available to any Users previously removed from the Site by BorB. These
                    terms are not boilerplate. If you disagree with any of them, believe that any should not apply to
                    you, or wish to negotiate these terms, please contact us at hi@borb.fi and immediately navigate away
                    from the Site. Do not use the Site until you and BorB have agreed upon new terms of use. Access and
                    Use of the Site and Smart Contracts Subject to the terms and conditions of this Agreement, you are
                    hereby granted a non-exclusive, limited, non-transferable, freely revocable license to use the Site
                    as permitted by the features of the Site. BorB reserves all rights not expressly granted herein in
                    the Site and the BorB Content (as defined below). BorB may terminate this license, in whole or in
                    part, at any time for any reason or no reason. Site Rules You agree not to engage in any of the
                    following prohibited activities: (i) copying, distributing, or disclosing any part of the Site in
                    any medium, including without limitation by any automated or non-automated “scraping”; (ii) using
                    any automated system, including without limitation “robots,” “spiders,” “oﬄine readers,” etc., to
                    access the Site in a manner that sends more request messages to the BorB servers than a human can
                    reasonably produce in the same period of time by using a conventional on-line web browser (except
                    that BorB grants the operators of public search engines revocable permission to use spiders to copy
                    publically available materials from Site for the sole purpose of and solely to the extent necessary
                    for creating publicly available searchable indices of the materials, but not caches or archives of
                    such materials); (iii) transmitting spam, chain letters, or other unsolicited email; (iv) attempting
                    to interfere with, compromise the system integrity or security or decipher any transmissions to or
                    from the servers running the Site; (v) taking any action that imposes, or may impose at our sole
                    discretion an unreasonable or disproportionately large load on our infrastructure; (vi) uploading
                    invalid data, viruses, worms, or other software agents through the Site; (vii) collecting or
                    harvesting any personally identifiable information, including account names, from the Site; (viii)
                    using the Site for any commercial solicitation purposes; (ix) impersonating another person or
                    otherwise misrepresenting your affiliation with a person or entity, conducting fraud, hiding or
                    attempting to hide your identity; (x) interfering with the proper working of the Site; (xi)
                    accessing any content on the Site through any technology or means other than those provided or
                    authorized by the Site; or (xii) bypassing the measures we may use to prevent or restrict access to
                    the Site, including without limitation features that prevent or restrict use or copying of any
                    content or enforce limitations on use of the Site or the content therein. We may, without prior
                    notice, change the Site; stop providing the Site or features of the Site, to you or to Users
                    generally; or create usage limits for the Site. We may permanently or temporarily terminate or
                    suspend your access to the Site without notice and liability for any reason, including if in our
                    sole determination you violate any provision of this Agreement, or for no reason. Upon termination
                    for any reason or no reason, you continue to be bound by this Agreement. You are solely responsible
                    for your interactions with other BorB Users and Protocol Users. We reserve the right, but have no
                    obligation, to monitor disputes between you and other Users. BorB shall have no liability for your
                    interactions with other Users, or for any User’s action or inaction. Site Location The Site is
                    controlled and operated from facilities in the Dubai free zone. BorB makes no representations that
                    the Site is appropriate or available for use any locations. Those who access or use the Site from
                    any jurisdictions do so at their own volition and are entirely responsible for compliance with all
                    applicable Dubai free zone and local laws and regulations, including but not limited to export and
                    import regulations. BorB makes no representations that the Site is appropriate or available for use
                    in other locations. Those who access or use the Services or content from other countries do so at
                    their own volition and are responsible for compliance with local law. You may not use the Site if
                    you are a resident of a country embargoed by the United States and European Union, or are a foreign
                    person or entity blocked or denied by the United States government and European Union.
                </Text>
                <SubTitle>Electronic Communications</SubTitle>
                <Text>
                    Visiting the Site constitutes electronic communications. You consent to receive electronic
                    communications and you agree that all agreements, notices, disclosures and other communications that
                    we provide to you electronically, via the Site, satisfy any legal requirement that such
                    communications be in writing. The foregoing does not affect your statutory rights.
                </Text>
                <SubTitle>Updates</SubTitle>
                <Text>
                    You understand that the Site and the related services are evolving. As a result, BorB may require
                    you to accept updates to the Site and the related services that you have previously installed or
                    used. You acknowledge and agree that BorB may update the Site with or without notifying you. You may
                    need to update third-party software from time to time in order to use the Site.
                </Text>
                <SubTitle>Termination/Access Restriction</SubTitle>
                <Text>
                    BorB reserves the right, in its sole discretion, to terminate your access to the Site and its
                    related services or any portion thereof at any time, without notice. This agreement is governed by
                    the laws of the Dubai free zone. You agree that no joint venture, partnership, employment, or agency
                    relationship exists between you and BorB as a result of this agreement or use of the Site. BorB's
                    performance of this agreement is subject to existing laws and legal process, and nothing contained
                    in this agreement is in derogation of BorB's right to comply with governmental, court and law
                    enforcement requests or requirements relating to your use of the Site or information provided to or
                    gathered by BorB with respect to such use. If any part of this agreement is determined to be invalid
                    or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers
                    and liability limitations set forth above, then the invalid or unenforceable provision will be
                    deemed superseded by a valid, enforceable provision that most closely matches the intent of the
                    original provision and the remainder of the agreement shall continue in effect.
                </Text>
                <SubTitle>Our Proprietary Rights</SubTitle>
                <Text>
                    The Site and all materials therein or transferred thereby, including, without limitation, software,
                    images, text, graphics, illustrations, logos, patents, trademarks, service marks, copyrights,
                    photographs, audio, videos, music, and User Content belonging to other Users (the “BorB Content”),
                    and all Intellectual Property Rights related thereto, are the exclusive property of BorB and its
                    licensors. Except as explicitly provided herein, nothing in this Agreement shall be deemed to create
                    a license in or under any such Intellectual Property Rights, and you agree not to sell, license,
                    rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish,
                    adapt, edit or create derivative works from any BorB Content. Use of the BorB Content for any
                    purpose not expressly permitted by this Agreement is strictly prohibited. You may choose to or we
                    may invite you to submit comments or ideas about the Site, including without limitation about how to
                    improve the Site or our products (“Ideas”). By submitting any Idea, you agree that your disclosure
                    is gratuitous, unsolicited and without restriction and will not place BorB under any fiduciary or
                    other obligation, and that we are free to use the Idea without any additional compensation to you,
                    and/or to disclose the Idea on a non-confidential basis or otherwise to anyone. You further
                    acknowledge that, by acceptance of your submission, BorB does not waive any rights to use similar or
                    related ideas previously known to BorB, or developed by its employees, or obtained from sources
                    other than you.
                </Text>
                <SubTitle>No Professional Advice</SubTitle>
                <Text>
                    If the Site provides professional information (for example, medical, legal, or financial), such
                    information is for informational purposes only and should not be construed as professional advice.
                    No action should be taken based upon any information contained in the Site. You should seek
                    independent professional advice from a person who is licensed and/or qualified in the applicable
                    area.
                </Text>
                <SubTitle>Privacy</SubTitle>
                <Text>
                    We care about the privacy of our Users. You understand that by using the Sites you consent to the
                    collection, use and disclosure of personally identifiable information and aggregate data, and to
                    have your personally identifiable information collected, used, transferred to and processed in the
                    Dubai free zone.
                </Text>
                <SubTitle>Security</SubTitle>
                <Text>
                    BorB uses commercially reasonable physical, managerial, and technical safeguards to preserve the
                    integrity and security of your personal information and implement your privacy settings. However, we
                    cannot guarantee that unauthorized third parties will never be able to defeat our security measures
                    or use your personal information for improper purposes. You acknowledge that you provide your
                    personal information at your own risk.
                </Text>
                <SubTitle>Third-Party Links and Information</SubTitle>
                <Text>
                    The Site may contain links to third-party materials that are not owned or controlled by BorB. BorB
                    does not endorse or assume any responsibility for any such third-party sites, information,
                    materials, products, or services. If you access a third-party website or service from the Site on or
                    through any third-party website or service, you do so at your own risk, and you understand that this
                    Agreement and BorB Privacy Policy do not apply to your use of such sites. You expressly relieve BorB
                    from any and all liability arising from your use of any third-party website, service, or content.
                    Additionally, your dealings with or participation in promotions of advertisers found on the Site,
                    including payment and delivery of goods, and any other terms (such as warranties) are solely between
                    you and such advertisers. You agree that BorB shall not be responsible for any loss or damage of any
                    sort relating to your dealings with such advertisers.
                </Text>
                <SubTitle>Indemnity</SubTitle>
                <Text>
                    You agree to defend, indemnify and hold harmless BorB and its subsidiaries, agents, licensors,
                    managers, and other affiliated companies, and their employees, contractors, agents, officers and
                    directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or
                    debt, and expenses (including but not limited to attorney’s fees) arising from: (i) relating to or
                    arising out of your use of or inability to use the Site or services, any postings or submissions
                    made by you or other users, including any data or content transmitted or received by you; (ii) your
                    violation of any term of this Agreement, including without limitation your breach of any of the
                    representations and warranties above; (iii) your violation of any third-party right, including
                    without limitation any right of privacy or Intellectual Property Rights; (iv) your violation of any
                    applicable law, rule or regulation; (v) any content that you submit to the Site including without
                    limitation misleading, false, or inaccurate information; (vi) your willful misconduct; or (vii) any
                    other party’s access and use of the Site with your unique username, password or other appropriate
                    security code. BorB reserves the right, at its own cost, to assume the exclusive defense and control
                    of any matter otherwise subject to indemnification by you, in which event you will fully cooperate
                    with PM Research in asserting any available defenses.
                </Text>
                <SubTitle>Assumption of Risk; Release of Claims</SubTitle>
                <Text>
                    You acknowledge that the Site (including without limitation the Protocol and the Smart Contracts)
                    and your use of the Site contain certain risks, including without limitation the following risks:
                    That any Smart Contracts you interact with are entirely your own responsibility / liability, and
                    BorB is not a party to the Smart Contracts;At any time, your access to your Assets may be suspended
                    or terminated or there may be a delay in your access or use of your Assets which may result in the
                    Assets diminishing in value or you being unable to complete a Smart Contract and the Protocol may be
                    suspended or terminated for any or no reason, which may limit your access to your Assets.
                    Accordingly, you expressly agree that: (A) you assume all risk in connection with your access and
                    use of the Site, the Protocol and the Smart Contracts; and (B) that you expressly waive and release
                    BorB from any and all liability, claims, causes of action, or damages arising from or in any way
                    related to your use of the Site, the Protocol or the Smart Contracts.
                </Text>
                <SubTitle>No Warranty</SubTitle>
                <Text>
                    The Site is provided on an “as is” and “as available” basis. Use of the Site is at your own risk. To
                    the maximum extent permitted by applicable law, the Site is provided without warranties of any kind,
                    whether express or implied, including, but not limited to, implied warranties of merchantability,
                    fitness for a particular purpose, or non-infringement. No advice or information, whether oral or
                    written, obtained by you from BorB or through the Site will create any warranty not expressly stated
                    herein. Without limiting the foregoing, BorB, its subsidiaries, its affiliates, and its licensors do
                    not warrant that the content is accurate, reliable or correct; that the Site will meet your
                    requirements; that the Site will be available at any particular time or location, uninterrupted or
                    secure; that any defects or errors will be corrected; or that the Site is free of viruses or other
                    harmful components. Any content downloaded or otherwise obtained through the use of the Site is
                    downloaded at your own risk and you will be solely responsible for any damage to your computer
                    system or mobile device or loss of data that results from such download or your use of the Site.
                    BorB does not warrant, endorse, guarantee, or assume responsibility for any product or site
                    advertised or offered by a third party through the Site or any hyperlinked website or site, and BorB
                    will not be a party to or in any way monitor any transaction between you and third-party providers
                    of products or services.
                </Text>
                <SubTitle>Limitation of Liability</SubTitle>
                <Text>
                    The information, software, products, and services included in or available through the site may
                    include inaccuracies or typographical errors. changes are periodically added to the information
                    herein. BorB and/or its suppliers may make improvements and/or changes in the site at any time. BorB
                    and/or its suppliers make no representations about the suitability, reliability, availability,
                    timeliness, and accuracy of the information, software, products, services and related graphics
                    contained on the site for any purpose. to the maximum extent permitted by applicable law, all such
                    information, software, products, services and related graphics are provided "as is" without warranty
                    or condition of any kind. BorB and/or its suppliers hereby disclaim all warranties and conditions
                    with regard to this information, software, products, services and related graphics, including all
                    implied warranties or conditions of merchantability, fitness for a particular purpose, title and
                    non-infringement. to the maximum extent permitted by applicable law, in no event shall BorB and/or
                    its suppliers be liable for any direct, indirect, punitive, incidental, special, consequential
                    damages or any damages whatsoever including, without limitation, damages for loss of use, data or
                    profits, arising out of or in any way connected with the use or performance of the site, with the
                    delay or inability to use the site or related services, the provision of or failure to provide
                    services, or for any information, software, products, services and related graphics obtained through
                    the site, or otherwise arising out of the use of the site, whether based on contract, tort,
                    negligence, strict liability or otherwise, even if BorB or any of its suppliers has been advised of
                    the possibility of damages. because some states/jurisdictions do not allow the exclusion or
                    limitation of liability for consequential or incidental damages, the above limitation may not apply
                    to you. if you are dissatisfied with any portion of the site, your sole and exclusive remedy is to
                    discontinue using the site. To the maximum extent permitted by applicable law, in no event shall
                    BorB, its affiliates, agents, directors, employees, suppliers or licensors be liable for any direct
                    or indirect, punitive, incidental, special, consequential or exemplary damages, including without
                    limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out
                    of or relating to the use of, or inability to use, this Site or related services, the provision of
                    or failure to provide services, or for any information, software, products, services and related
                    graphics obtained through the site, or otherwise arising out of the use of the site, whether based
                    on contract, tort, negligence, strict liability or otherwise, even if BorB or any of its suppliers
                    has been advised of the possibility of damages . Under no circumstances will BorB be responsible for
                    any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of
                    the Site or the information contained therein.To the maximum extent permitted by applicable law,
                    BorB assumes no liability or responsibility for any (i) errors, mistakes, or inaccuracies of
                    content; (ii) personal injury or property damage, of any nature whatsoever, resulting from your
                    access to or use of our site; (iii) any unauthorized access to or use of our secure servers and/or
                    any and all personal information stored therein; (iv) any interruption or cessation of transmission
                    to or from the Site; (v) any bugs, viruses, trojan horses, or the like that may be transmitted to or
                    through our site by any third party; (vi) any errors or omissions in any content or for any loss or
                    damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise
                    made available through the Site; and/or (vii) User Content or the defamatory, offensive, or illegal
                    conduct of any third party.
                </Text>
                <SubTitle>Limitations as Allowed by Law</SubTitle>
                <Text>
                    Federal law, some states, provinces and other jurisdictions do not allow the exclusion and
                    limitations of certain implied warranties, or the exclusion or limitation of incidental or
                    consequential damages, so the above limitations or exclusions may not apply to you. This agreement
                    gives you specific legal rights, and you may also have other rights which vary from state to state.
                    The disclaimers, exclusions, and limitations of liability under this agreement will not apply to the
                    extent prohibited by applicable law.
                </Text>
                <SubTitle>Governing Law, Arbitration, and Class Action/Jury Trial Waiver</SubTitle>
                <Text>
                    Arbitration. Read this section carefully because it requires the parties to arbitrate their disputes
                    and limits the manner in which you can seek relief from BorB. For any dispute with BorB you agree to
                    first contact us at hi@borb.fi and attempt to resolve the dispute with us informally. In the
                    unlikely event that BorB has not been able to resolve a dispute it has with you after hundred eighty
                    (180) days, we each agree to resolve any claim, dispute, or controversy (excluding any claims for
                    injunctive or other equitable relief as provided below) arising out of or in connection with or
                    relating to this Agreement, or the breach or alleged breach thereof (collectively, “Claims”).
                </Text>
                <br />
                <Headline id="introduction">Privacy Policy</Headline>
                <Text>
                    Welcome to borb.fi (the "Site"). BorB ("BorB" , "we", "us" or "our") is committed to treating the
                    personal and corporate information of our users with respect and sensitivity. Our privacy commitment
                    is described in this privacy policy (the "Privacy Policy"). BorB provides the Site to you subject to
                    your acceptance of the Privacy Policy. BorB is a group of early prediction market researchers who
                    are committed to furthering the adoption, research and academic discoveries within predictive
                    forecasting. BorB operates educational and user resources for existing and promising applications of
                    predictive software. BorB makes no money from these web properties. BorB operates the borb.fi domain
                    name and various social media accounts. Cookies notice: What we collect and how we use and share
                    your information We may collect session information from you using Cookies.
                </Text>
                <SubTitle>Cookies</SubTitle>
                <Text>
                    Cookies are small pieces of information that a website sends to your computer’s hard drive while you
                    are viewing the website. We only use session Cookies (which expires once you close your web browser)
                    to provide information about the number of users accessing the Site. No personal or other type of
                    data is collected.
                </Text>
                <SubTitle>Information choices and changes</SubTitle>
                <Text>
                    In the unlikely event we collected personal information from you, you may send requests about
                    personal information to our Contact Information below, such as deleting any of your information we
                    may have collected. You can typically remove and reject cookies from our Site with your browser
                    settings. Many browsers are set to accept cookies until you change your settings. If you remove or
                    reject our cookies, it could affect how our Site works for you.
                </Text>
                <SubTitle>A Note About Children</SubTitle>
                <Text>
                    We do not intentionally gather personal information, such as first and last name, email address,
                    postal address, etc. from visitors who are under the age of 16. If a child under 16 submits personal
                    information to BorB and we learn that the personal information is the information of a child under
                    16, we will attempt to delete the information as soon as possible. If you believe that we might have
                    any personal information from a child under 16, please contact us at hi@borb.fi
                </Text>
                <SubTitle>A Note to Users Outside of the Dubai free zone</SubTitle>
                <Text>
                    Although we do not collect information from you, by visiting the Site Site and providing us with any
                    information, you acknowledge and agree that the information you provide, including personal
                    information may be used for the purposes identified in the Privacy Policy. In addition, your
                    personal information may be processed in the country in which it was collected and in other
                    countries, including the United States, where laws regarding processing of personal information may
                    be less stringent than the laws in your country. By providing any of your information, you consent
                    to such transfer.
                </Text>
                <SubTitle>Contact information</SubTitle>
                <Text>We welcome your comments or questions about this privacy policy.</Text>
                <SubTitle>You may also contact us at our address:</SubTitle>
                <Text>hi@borb.fi</Text>
                <SubTitle>Changes to this privacy policy</SubTitle>
                <Text>
                    We may change this privacy policy. If we make any changes, we will change the Last Updated date
                    above.
                </Text>
            </div>
        </StyledAbout>
    )
}

export default AboutPage
