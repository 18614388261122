import { useEffect, useState } from 'react'
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr'
import * as SignalR from '@aspnet/signalr'
import { useAppDispatch, useAppSelector } from './redux'
import { gameSlice } from '../store/reducers/gameSlice'
import { pointsSlice } from '../store/reducers/chartSlice'

export interface NewPriceInUsdVm {
    BTC: PriceData
    ETH: PriceData
    SOL: PriceData
    BNB: PriceData
    ADA: PriceData
    DOT: PriceData
    MATIC: PriceData
    DOGE: PriceData
    ATOM: PriceData
    AVAX: PriceData
    date: string
}

export interface PriceData {
    value: number
    roundId: string
}

export const useUpdatePrices = () => {
    const { currency } = useAppSelector((state) => state.gameSlice)
    const { setCurrencyPrice } = gameSlice.actions
    const { addPoint } = pointsSlice.actions
    const dispatch = useAppDispatch()
    const [connection, setConnection] = useState<HubConnection>()

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${import.meta.env.VITE_APP_BACKEND_URI!}/wssrates`, {
                withCredentials: false,
                skipNegotiation: true,
                transport: SignalR.HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build()

        setConnection(newConnection)
    }, [])

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    connection.on('NewPriceSetted', (payload: NewPriceInUsdVm) => {
                        //todo destructurize /player?course=JS&lessonId=63297a9684ab95f41e81c175
                        const currentCurrency = Object.entries(payload).find(
                            (x) => x[0].toUpperCase() === currency.ticker
                        )?.[1]
                        dispatch(setCurrencyPrice(currentCurrency))
                        dispatch(
                            addPoint({
                                timeStamp: payload.date,
                                value: currentCurrency.value,
                                roundId: currentCurrency.roundId,
                            })
                        )
                        // console.log('currency=========>', currency)
                        // console.log('newPrice=========>', newPrice)
                        // console.log('payload==============>', payload)
                    })
                })
                .catch((e: any) => console.log('Connection failed: ', e))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection])
}
