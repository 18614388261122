import { ethers } from 'ethers'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import Btoken from '../../assets/token_logo.webp'
// import Btoken from '../../assets/btoken.png'
import { useAccount } from 'wagmi'
import { useEthersProvider } from '../../hooks/useEthersProvider'
import { useEthersSigner } from '../../hooks/useEthersSigner'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { allowedAssets } from '../../lib/data'
import { Card, CardContentText, Row, RowText } from '../EarnPage/components/styles'
import { addToMM } from '../../utils/sharedFunctions'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { B__factory, Pool__factory } from '../../@types/typechain-types'
import { Tooltip } from 'react-tooltip'
import InfoIcon from '../../assets/info-icon.svg?react'
import styled from 'styled-components'
import { getInvested } from '../../store/api/referal'

interface SliderProps {
    reload: number 
}

export function Slider({ reload }: SliderProps) {
    const { t } = useTranslation()
    const swiperRef = useRef<HTMLElement | null>(null)
    const { address } = useAccount()
    const provider = useEthersProvider()
    const signer = useEthersSigner()
    const { poolContractAddress } = useAppSelector((state) => state.gameSlice)
    const { usdtDeposited, usdcDeposited } = useAppSelector((state) => state.referalRewardsSlice)

    const tablet = useMediaQuery('(max-width: 768px)')
    const mobile = useMediaQuery('(max-width: 480px)')
    const getSlidesPerView = React.useMemo(() => {
        if (mobile) return 1.2
        if (tablet) return 1.8
        return 3
    }, [tablet, mobile])

    const [balances, setBalances] = useState<number[]>([0, 0, 0])
    const [addressB, setAddressB] = useState(ethers.ZeroAddress)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (address) {
            dispatch(
                getInvested({
                    address: address!,
                })
            )
        }
    }, [address,reload])

    useEffect(() => {
        // Using an IIFE
        ;(async function anyNameFunction() {
            if (!provider) return

            const poolContract = Pool__factory.connect(poolContractAddress, provider!)
            let newBalances: number[] = []
            try {
                for (let i = 0; i < allowedAssets.length; i++) {
                    const balance = await poolContract.referalBalanceOf(i, address!)
                    newBalances[i] = Number.parseFloat(ethers.formatUnits(balance, 18))
                }
                const tb = await poolContract.connect(signer).tokenB()
                newBalances.push(usdtDeposited)
                newBalances.push(usdcDeposited)
                setAddressB(tb)
                newBalances[allowedAssets.length] = Number.parseFloat(
                    ethers.formatUnits(await poolContract.stakedB(address!), 18)
                )

                setBalances([...newBalances])
            } catch (e: any) {
                console.error(e)
            }
        })()
    }, [address, reload, signer])

    return (
        <div className="swiper">
            <Swiper
                spaceBetween={16}
                onInit={(v) => {
                    swiperRef.current = v.el
                }}
                slidesPerView={getSlidesPerView}
                observeParents={true}
                observer={true}
                noSwiping={false}
                noSwipingClass={'swiper-slide'}
            >
                <SwiperSlide key={0}>
                    <Card>
                        <Row>
                            <img src={Btoken} alt="B" className="Btoken" />
                            <RowText>B</RowText>
                        </Row>
                        <CardContentText style={{ marginBottom: '0' }}>
                            {balances[allowedAssets.length]}
                        </CardContentText>
                        <RowText style={{ marginTop: '18px' }}>
                            Ratio 1:1{'   '}
                            <InfoIcon
                                data-tooltip-id="InfoIcon-tooltip"
                                data-tooltip-content={`Trading volume (24h) ≥ $0 - $100K | Ratio 1:1 (1 B = 1 USDC/USDT)

                                                        Trading volume (24h) ≥ $100K - $200K | Ratio 1:2 (1 B = 2 USDC/USDT)

                                                        Trading volume (24h) ≥ $200K - $400K | Ratio 1:4 (1 B = 4 USDC/USDT)

                                                        Trading volume (24h) ≥ $400K - $800K | Ratio 1:8 (1 B = 8 USDC/USDT)

                                                        Trading volume (24h) ≥ $800K - $1.6M | Ratio 1:16 (1 B = 16 USDC/USDT)

                                                        and so on…`}
                            />{' '}
                            <CardSubLink>
                                <p onClick={() => addToMM(addressB, 'B')}>Add to MetaMask</p>
                                <img src="/images/earn/arrow.svg" alt="Claim" />
                            </CardSubLink>
                        </RowText>
                    </Card>
                </SwiperSlide>
                {allowedAssets.map((asset, _idx) => (
                    <SwiperSlide key={_idx + 1}>
                        <Card>
                            <Row>
                                <img src={asset.img} alt={asset.name} />
                                <RowText>{asset.name}</RowText>
                            </Row>
                            <CardContentText>{`$ ${
                                (asset.name == 'USDT' ? usdtDeposited : usdcDeposited) ?? 0
                            }`}</CardContentText>
                        </Card>
                    </SwiperSlide>
                ))}
            </Swiper>
            <Tooltip id="InfoIcon-tooltip" className="tooltip" />
        </div>
    )
}
const CardSubLink = styled.div`
    margin-top: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    margin-left: 60px;

    p {
        color: var(--green);
        font-size: 14px;
        font-weight: 600;
        margin-right: 4px;
    }
`
