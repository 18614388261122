import { BetCFDVm, BetFPCVm } from "../@types/Game/bet"

export function getQueryVariable(variable: string) {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
            return pair[1]
        }
    }
    return false
}

export const addressToPointsFormat = (
    address: string,
    firstPartTo: number,
    secondPartFrom: number
): string => {
    return `${address.slice(0, firstPartTo)}....${address.slice(
        secondPartFrom
    )}`
}

export function convertBetCFDVmToBetFPCVm(betCFDVm: BetCFDVm): BetFPCVm {
    const defaultPotentialReward = 0

    return {
        timeframe: betCFDVm.timeframe,
        betId: betCFDVm.betId,
        userAddress: betCFDVm.userAddress,
        betType: betCFDVm.betType,
        currency: betCFDVm.currency,
        amount: betCFDVm.amount,
        potentialReward: defaultPotentialReward,
        roundId: betCFDVm.roundId,
        lockPrice: betCFDVm.lockPrice,
        lockedAt: betCFDVm.lockedAt,
        points: betCFDVm.points,
        lineColorGreen: betCFDVm.lineColorGreen,
        closePrice: betCFDVm.closePrice,
    }
}
