import { combineReducers, configureStore } from '@reduxjs/toolkit'
import appSettings from './reducers/appSettingsSlice'
import chartSlice from './reducers/chartSlice'
import pointsSlice from './reducers/chartSlice'
import gameSlice from './reducers/gameSlice'
import historyFPCSlice from './reducers/historyFPCSlice'
import referalRewardsSlice  from './reducers/referalRewardsSlice'
import supplySlice from './reducers/supplySlice'
import historyCFDSlice from './reducers/historyCFDSlice'

const rootReducer = combineReducers({
    appSettings,
    gameSlice,
    pointsSlice,
    supplySlice,
    historyFPCSlice,
    historyCFDSlice,
    referalRewardsSlice,
    chartSlice,
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: true, immutableCheck: false,
                serializableCheck: false,
            }),
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
