import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { appSettings } from '../store/reducers/appSettingsSlice'

import en from '../assets/en.webp'
import zh from '../assets/zh.webp'
import ru from '../assets/ru.webp'
import fr from '../assets/fr.webp'
import ja from '../assets/ja.webp'
import es from '../assets/es.webp'
import ko from '../assets/ko.webp'
import id from '../assets/id.webp'
import vn from '../assets/vi.webp'
import ua from '../assets/ua.webp'
import pt from '../assets/pt.webp'


const data = [
    {
        name: 'English',
        short: 'EN',
        url: en,
    },
    {
        name: '简体中文',
        short: 'ZH',
        url: zh,
        disabled: true,
    },
    {
        name: 'Русский',
        short: 'RU',
        url: ru,
        disabled: true,
    },
    {
        name: 'Français',
        short: 'FR',
        url: fr,
        disabled: true,
    },
    {
        name: '日本語',
        short: 'JA',
        url: ja,
        disabled: true,
    },
    {
        name: 'Español',
        short: 'ES',
        url: es,
        disabled: true,
    },
    {
        name: '한국어',
        short: 'KO',
        url: ko,
        disabled: true,
    },
    {
        name: 'Bahasa Indonesia',
        short: 'IND',
        url: id,
        disabled: true,
    },
    {
        name: 'Tiếng Việt',
        short: 'VN',
        url: vn,
        disabled: true,
    },
    {
        name: 'Українська',
        short: 'UA',
        url: ua,
        disabled: true,
    },
    {
        name: 'Português',
        short: 'PT',
        url: pt,
        disabled: true,
    },
]

const LangaugePopup = () => {
    const { isLangaugePopup } = useAppSelector((state) => state.appSettings)
    const { setIsLangaugePopup } = appSettings.actions
    const dispatch = useAppDispatch()
    let ref = useRef(null)
    useOnClickOutside(ref, () => dispatch(setIsLangaugePopup(false)))

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t, i18n } = useTranslation()

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    return (
        <>
            {isLangaugePopup && (
                <StyledPopup ref={ref}>
                    <div className="head">
                        <h4 className="title">Change language</h4>
                        <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            onClick={() => dispatch(setIsLangaugePopup(false))}
                        >
                            <g>
                                <g>
                                    <polygon
                                        points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 
			512,452.922 315.076,256 		"
                                    />
                                </g>
                            </g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                        </svg>
                    </div>

                    <div className="grid">
                        {data.map((card) => (
                            <div
                                key={card.short}
                                className={`card ${card.disabled ? 'disabled' : ''}`}
                                onClick={() => dispatch(setIsLangaugePopup(false))}
                            >
                                <img className="card_img" src={card.url} alt={card.name} />
                                <p
                                    onClick={() => {
                                        if (card.disabled) {
                                            return
                                        }

                                        changeLanguage(card.short.toLowerCase())
                                    }}
                                >
                                    {card.name} &nbsp;-&nbsp; {card.short}
                                </p>
                            </div>
                        ))}
                    </div>
                </StyledPopup>
            )}
        </>
    )
}

export default LangaugePopup

const StyledPopup = styled.div`
    width: 550px;
    padding: 24px;
    box-shadow: 0 5px 20px rgb(0 0 0 / 25%);
    border-radius: 16px;
    position: absolute;
    z-index: 11111;
    background: ${(props) => props.theme.backgroundContent};
    right: 50px;
    top: 80px;
    .title {
        font-size: 20px;
        color: ${(props) => props.theme.arrowBackgroundColor};
    }
    .head {
        padding-bottom: 18px;
        margin-bottom: 24px;
        border-bottom: 1px solid ${(props) => props.theme.arrowBackgroundColor};
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .head svg {
        width: 16px;
        height: 16px;
    }
    polygon {
        fill: ${(props) => props.theme.arrowBackgroundColor};
    }
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 4px;
    }
    svg {
        cursor: pointer;
    }
    .card_img {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }
    .card {
        padding: 0 16px;
        height: 48px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.2s all ease;
        border: 1px solid transparent;
        border-radius: 8px;
        padding: 0 15px;
        :not(.disabled) {
            &:hover,
            &:active {
                color: #fff;
                background: ${(props) => props.theme.borderColor};
                border-color: lightgray;
            }
        }
        &.disabled {
            color: ${(props) => props.theme.inputDisabledColor};
            cursor: default;
            p {
                color: ${(props) => props.theme.inputDisabledColor};
            }
        }
    }
    p {
        color: ${(props) => props.theme.arrowBackgroundColor};
        font-size: 16px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100vh;
        right: 0;
        top: 0;
        box-sizing: border-box;
        position: fixed;
        overflow-y: scroll;
        .grid {
            grid-template-columns: 1fr;
            grid-gap: 24px;
        }
    }
`
