import { useTranslation } from 'react-i18next'
import { RewardTable } from './components/RewardTable'
import { Slider } from './components/Slider'
import { RewardTitle, StyledEarn, SubText, Title } from './components/styles'
import { SliderRevenue } from './components/SliderRevenue'

const EarnPage = () => {
    const { t } = useTranslation()
    return (
        <StyledEarn>
            <div className="container">
                <Title>Revenue share</Title>
                <SubText>Earn by trading. Earn up to 0.4% for every trade you make.</SubText>
                <SliderRevenue />
            </div>
            <div className="container" style={{ marginTop: '100px' }}>
                <Title>Referral program</Title>
                <SubText>Earn by referring. Earn 0.3% for every trade your referrals make.</SubText>
                <Slider />
                <RewardTitle>{t('EarnPage.Reward')}</RewardTitle>
                <RewardTable />
            </div>
        </StyledEarn>
    )
}

export default EarnPage
