import { StyledAbout } from './components/styles'
import { useAppSelector } from '../../hooks/redux'

const HelpPage = () => {
    const { themeSettings } = useAppSelector((state) => state.appSettings)
    return (
        <StyledAbout className="container">
            <div style={{ color: themeSettings.arrowBackgroundColor, width: '100%', paddingBottom: '10px' }}>
                Sometimes you may find yourself facing a problem that doesn’t have a clear solution. These
                troubleshooting tips may help you solve problems you run into.
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <div
                style={{
                    color: themeSettings.arrowBackgroundColor,
                    width: '100%',
                    paddingBottom: '10px',
                    fontStyle: 'italic',
                }}
            >
                INSUFFICIENT_AMOUNT
            </div>
            <br />
            <div style={{ color: themeSettings.arrowBackgroundColor, width: '100%', paddingBottom: '10px' }}>
                The error is caused by trying to add or remove liquidity for a liquidity pool (LP) with an insufficient
                amount of token.
            </div>
            <div
                style={{
                    color: themeSettings.arrowBackgroundColor,
                    width: '100%',
                    fontWeight: 'bold',
                    paddingBottom: '30px',
                }}
            >
                Solution: Refresh your page and try again, or try again later.
            </div>
            <div
                style={{
                    color: themeSettings.arrowBackgroundColor,
                    width: '100%',
                    paddingBottom: '10px',
                    fontStyle: 'italic',
                }}
            >
                Why can’t I see my winnings in my wallet?
            </div>
            <div style={{ color: themeSettings.arrowBackgroundColor, width: '100%', paddingBottom: '10px' }}>
                When you collect winnings, they might not appear in your wallet’s transaction logs as usual. This is
                because they use a different type of transaction: Internal transactions.
            </div>
            <div
                style={{
                    color: themeSettings.arrowBackgroundColor,
                    width: '100%',
                    fontWeight: 'bold',
                    paddingBottom: '30px',
                }}
            >
                Solution: Enter your wallet address on BscScan, then check the “Internal Txns” tab to confirm that
                they’ve arrived.
            </div>
            <div
                style={{
                    color: themeSettings.arrowBackgroundColor,
                    width: '100%',
                    paddingBottom: '10px',
                    fontStyle: 'italic',
                }}
            >
                Why aren’t the results of my round showing?
            </div>
            <div style={{ color: themeSettings.arrowBackgroundColor, width: '100%', paddingBottom: '10px' }}>
                There’s a 15 block buffer on each round, which can cause delays of up to 45 seconds after the end of a
                round. This buffer is to accommodate for the fact that we may not be able to reliably fetch a price and
                end a round immediately: various blockchain factors affect the speed in which transactions get confirmed
                on the network.
            </div>
           
            <br />
            <br />
            <br />
            <br />
            <br />

            <div
                style={{
                    color: themeSettings.arrowBackgroundColor,
                    width: '100%',
                    paddingBottom: '10px',
                    fontStyle: 'italic',
                }}
            >
                The round's result changed after the round ended! Why?
            </div>
            <div style={{ color: themeSettings.arrowBackgroundColor, width: '100%', paddingBottom: '10px' }}>
            Sometimes, after a round closes, the final result may be different from the chart interface while the round was live. This is because we use the ChainLink Oracle price feed to determine the final outcome of a round and the Oracle refreshes every 60 seconds. It's possible that during this short period, the Oracle might send an update while the transaction to trigger the calculating is being minted.
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
           
            <div
                style={{
                    color: themeSettings.arrowBackgroundColor,
                    width: '100%',
                    paddingBottom: '10px',
                    fontStyle: 'italic',
                }}
            >
                I cannot send transactions
            </div>
            <div style={{ color: themeSettings.arrowBackgroundColor, width: '100%', paddingBottom: '10px' }}>
            Make sure you have enough BNB in your wallet to interact with the platform. You must have BNB in your wallet for the transaction costs-- without it you won't be able to interact. Depending on the network status, the cost of the transactions may vary.
            </div>

            <br />
            <br />
            <br />
            <br />
           
            <div
                style={{
                    color: themeSettings.arrowBackgroundColor,
                    width: '100%',
                    paddingBottom: '10px',
                    fontStyle: 'italic',
                }}
            >
                My transaction is stuck pending confirmation
            </div>
            <div style={{ color: themeSettings.arrowBackgroundColor, width: '100%', paddingBottom: '10px' }}>
            In this situation make sure to not keep sending transactions, as every new transaction will be stuck pending the oldest transaction to be confirmed. You can get rid of the stuck transaction by speeding it up or cancelling it. Depending on the wallet you are using, you may have that option natively.

For example, Metamask or WalletConnect have both the options to cancel or speed up transactions. 
            </div>
        </StyledAbout>
    )
}

export default HelpPage
