import styled from 'styled-components'
import InfoIcon from '../../assets/info-icon.svg?react'
import Arrow from '../../assets/arrow-down-icon.svg?react'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { gameSlice } from '../../store/reducers/gameSlice'
import { ethers } from 'ethers'
import { SelectAsset } from '../../components/SelectAsset/SelectAsset'
import { getBalance, increaseAllowance, isAllowed } from '../../store/api/contracts'
import { supplySlice } from '../../store/reducers/supplySlice'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import CurrencyInput from 'react-currency-input-field'
import { getAPY } from '../../store/api/referal'
import { useEthersProvider } from '../../hooks/useEthersProvider'
import { useEthersSigner } from '../../hooks/useEthersSigner'
import { useAccount } from 'wagmi'
import { Slider } from './Slider'
import { addToMM } from '../../utils/sharedFunctions'
import { B__factory, Pool__factory } from '../../@types/typechain-types'
import { Tooltip } from 'react-tooltip'
import Btoken from '../../assets/token_logo.webp'
import { allowedAssets } from '../../lib/data'
type ActiveTab = 'Supply' | 'Withdraw' | 'Pool Run'
const ERROR_CODE_TX_REJECTED_BY_USER = 4001

const SupplyPage = () => {
    const { t } = useTranslation()
    const { address } = useAccount()
    const provider = useEthersProvider()
    const signer = useEthersSigner()
    const isMobile = useMediaQuery('(max-width: 768px)')
    const [amount, setAmount] = useState<string>('0')
    const [amountTokenPlus, setAmountTokenPlus] = useState<string>('0')
    const [activeTabName, setActiveTabName] = useState<ActiveTab>('Supply')

    const { asset, assetContractAddress, poolContractAddress, userBalance } = useAppSelector((state) => state.gameSlice)
    const { assetTokenPlusContractAddress, tokenPlusBalance, price, apy } = useAppSelector(
        (state1) => state1.supplySlice
    )
    const { setAssetContract, setUserBalance } = gameSlice.actions
    const { setAssetTokenPlusContract, setPrice, setTokenPlusBalance } = supplySlice.actions
    const dispatch = useAppDispatch()
    const [reload, setReload] = useState<number>(0)

    const [balanceB, setbalanceB] = useState(0n)
    const [balanceUsdPool, setbalanceUsdPool] = useState(0n)
    const [balanceUsdPoolBlocked, setbalanceUsdPoolBlocked] = useState(0n)
    const [balances, setBalances] = useState<number[]>([0, 0, 0])

    useEffect(() => {
        dispatch(getAPY({ asset: asset.id }))

        //const poolContract = Pool__factory.connect(poolContractAddress, provider!)

        // const tokenPlusAddress =
        //     asset.id == 0 ? '0x0c93a47d662ed483b307fa93905ef6c5ea164175' : '0x64003e0f005d57A6bf136b2caad4CF691bDA18D5'
        //await poolContract.getAssetTokenPlusAddress(asset.name)
        // dispatch(setAssetTokenPlusContract(tokenPlusAddress))
    }, [asset])

    useEffect(() => {
        // Using an IIFE
        ;(async function anyNameFunction() {
            if (!provider) return

            const poolContract = Pool__factory.connect(poolContractAddress, provider!)
            let newBalances: number[] = []
            try {
                clearAmounts()
                const assetAddress = await poolContract.getAssetAddress(asset.name)
                dispatch(setAssetContract(assetAddress))
                const tokenPlusAddress = await poolContract.getRewardTokenAddress(asset.name)
                dispatch(setAssetTokenPlusContract(tokenPlusAddress))
                if (
                    assetAddress !== ethers.ZeroAddress &&
                    //tokenPlusAddress !== ethers.constants.AddressZero &&
                    !!address
                ) {
                    dispatch(getAPY({ asset: asset.id }))
                    dispatch(setUserBalance(await getBalance(assetAddress!, address!, provider!)))
                    dispatch(setTokenPlusBalance(await getBalance(tokenPlusAddress!, address!, provider!)))
                    dispatch(setPrice(Number(ethers.formatUnits(await poolContract.getRewardTokenPrice(asset.id), 18))))
                    setbalanceB(await getBalance(await poolContract.tokenB(), address!, provider!))
                    setbalanceUsdPool(
                        await getBalance(assetAddress!, await poolContract.getRewardTokenAddress(asset.name), provider!)
                    )
                    setbalanceUsdPoolBlocked(await poolContract.getActiveBetsAmount(asset.id))
                    for (let i = 0; i < allowedAssets.length; i++) {
                        const aa = await poolContract.getRewardTokenAddress(allowedAssets[i].name)
                        const price = await poolContract.getRewardTokenPrice(i)
                        let balance = 0
                        try {
                            const bal = await getBalance(aa!, address!, provider!)
                            balance =
                                Number.parseFloat(ethers.formatUnits(bal, 18)) *
                                Number.parseFloat(ethers.formatUnits(price, 18))
                        } catch {}

                        newBalances[i] = balance
                    }
                    newBalances[allowedAssets.length] = Number.parseFloat(
                        ethers.formatUnits(await poolContract.stakedB(address!), 18)
                    )
                    setBalances([...newBalances])
                }
            } catch (e: any) {
                console.error(e)
            }
        })()
    }, [asset, address, reload])

    async function depositHandler() {
        if (!address) {
            toast.info(t('SupplyPage.Connect wallet'))
            return
        }
        const poolContract = Pool__factory.connect(poolContractAddress, provider!)
        const btoken = B__factory.connect(await poolContract.tokenB(), provider!)
        const formattedAmount = ethers.parseUnits(amount, 18)
        if ((await btoken.balanceOf(address)) < formattedAmount) {
            toast.info(t('SupplyPage.Not enought B amount to make deposit'))
            return
        }
        if (userBalance < formattedAmount) {
            toast.info(t('SupplyPage.Not enought balance to make deposit'))
            return
        }
        try {
            const rewardTokenAddress = await poolContract?.connect(provider).getRewardTokenAddress(asset.name)
            if (
                !(await isAllowed(address!, await poolContract.tokenB(), signer!, formattedAmount, poolContractAddress))
            ) {
                await increaseAllowance(await poolContract.tokenB(), signer!, poolContractAddress)
            }
            if (!(await isAllowed(address!, assetContractAddress, signer!, formattedAmount, rewardTokenAddress))) {
                await increaseAllowance(assetContractAddress, signer!, rewardTokenAddress)
            }
            const result = await poolContract.connect(signer!).makeDeposit(
                asset.id,
                formattedAmount,
                { gasLimit: 1000000 } //todo это убрать
            )
            toast
                .promise(result.wait(), {
                    pending: t<string>('SupplyPage.Depositing'),
                    success: t<string>('SupplyPage.Deposit added 👌'),
                    error: t<string>('SupplyPage.Error 🤯'),
                })
                .then((_) => setReload((prev) => prev + 1))
        } catch (error: any) {
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                toast.info('TX_REJECTED_BY_USER')
                return
            } else {
                //toast.error(error.message)
            }
        }
    }

    async function withdrawHandler() {
        if (!address) {
            toast.info(t<string>('SupplyPage.Connect wallet'))
        }
        const poolContract = Pool__factory.connect(poolContractAddress, provider!)
        try {
            const formattedAmount = ethers.parseUnits(amountTokenPlus.toString(), 18)
            const poolBalanceEnought = await poolContract?.poolBalanceEnough(ethers.parseUnits(amount, 18), asset.id)
            if (!poolBalanceEnought) {
                toast.warning(t('HomePage.Pool Balance Not Enought'))
                return
            }
            const result = await poolContract.connect(signer!).withdraw(
                asset.id,
                formattedAmount
                //{ gasLimit: 3000000 } //todo это убрать
            )
            toast
                .promise(result.wait(), {
                    pending: t<string>('SupplyPage.Withdrawing'),
                    success: t<string>('SupplyPage.Withdrawed 👌'),
                    error: t<string>('SupplyPage.Error withdraw 🤯'),
                })
                .then((_) => setReload((prev) => prev + 1))
        } catch (error: any) {
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                toast.info('TX_REJECTED_BY_USER')
                return
            } else {
                //toast.error(error.message)
            }
        }
    }

    async function poolrunHandler() {
        if (!address) {
            toast.info(t<string>('SupplyPage.Connect wallet'))
        }
        const poolContract = Pool__factory.connect(poolContractAddress, provider!)
        try {
            const poolBalanceEnought = await poolContract.connect(signer!).isPoolRunPossible()
            if (!poolBalanceEnought) {
                toast.warning(t('HomePage.Pool Balance Not Enought'))
                return
            }
            const result = await poolContract.connect(signer!).poolRun(
                { gasLimit: 3000000 } //todo это убрать
            )
            toast
                .promise(result.wait(), {
                    pending: t<string>('SupplyPage.Withdrawing'),
                    success: t<string>('SupplyPage.Withdrawed 👌'),
                    error: t<string>('SupplyPage.Error withdraw 🤯'),
                })
                .then((_) => setReload((prev) => prev + 1))
        } catch (error: any) {
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                toast.info('TX_REJECTED_BY_USER')
                return
            } else {
                //toast.error(error.message)
            }
        }
    }

    const changeTabHandler = (tabName: ActiveTab) => {
        setActiveTabName(tabName)
        clearAmounts()
    }

    const clearAmounts = () => {
        setAmount('0')
        setAmountTokenPlus('0')
    }
    const handleCurrencyChange = (value: string) => {
        value = value.replaceAll(',', '')
        const valueAsNumber = Number(value)
        if (isNaN(valueAsNumber) || Number(valueAsNumber) < 0) return
        if (value.split('.').length > 1 && value.split('.')[1].length > 2) {
            return
        }
        setAmount(value)
        const tokenPlusAmount = valueAsNumber / Number(price)
        setAmountTokenPlus(tokenPlusAmount.toString())
    }
    async function setInputValueHandlerWithdraw(value: string) {
        value = value.replaceAll(',', '')
        const valueAsNumber = Number(value)
        if (isNaN(valueAsNumber) || Number(valueAsNumber) < 0) return
        if (value.split('.').length > 1 && value.split('.')[1].length > 2) return
        setAmountTokenPlus(value)
        const tokenPlusAmount = valueAsNumber * Number(price)
        setAmount(tokenPlusAmount.toString())
    }

    return (
        <StyledSupply>
            <div className="container">
                <Title>{t('SupplyPage.Title')}</Title>
                <Subtitle>Provide B tokens to supply liquidity</Subtitle>
                <Slider reload={reload}/>
                <TabContainer>
                    <Tab active={activeTabName === 'Supply'} onClick={() => changeTabHandler('Supply')}>
                        <span>{t('SupplyPage.Supply')}</span>
                    </Tab>
                    <Tab active={activeTabName === 'Withdraw'} onClick={() => changeTabHandler('Withdraw')}>
                        <span>{t('SupplyPage.Withdraw')}</span>
                    </Tab>
                    <Tab active={activeTabName === 'Pool Run'} onClick={() => changeTabHandler('Pool Run')}>
                        <span>{t('Pool Run')}</span>
                    </Tab>
                </TabContainer>
                {activeTabName != 'Pool Run' && (
                    <InputContainer>
                        <InputWrapper>
                            <TitleContainer>
                                <SettingsTitle margin="9px">{activeTabName}</SettingsTitle>
                                {isMobile && (
                                    <SettingsTitle>{`Balance: ${Number(ethers.formatUnits(userBalance, 18)).toFixed(
                                        5
                                    )} ${asset.name}; ${Number(ethers.formatUnits(tokenPlusBalance, 18)).toFixed(5)} ${
                                        asset.name
                                    }+; ${Number(ethers.formatUnits(balanceB, 18)).toFixed(5)} B`}</SettingsTitle>
                                )}
                            </TitleContainer>
                            <div className="input-wrapper">
                                <SelectAsset addPlus={activeTabName == 'Withdraw'} />
                                {activeTabName === 'Supply' && (
                                    <CurrencyInput
                                        groupSeparator=","
                                        decimalSeparator="."
                                        className="input"
                                        value={amount}
                                        placeholder={t<string>('HomePage.Amount')}
                                        onChange={(e: any) => handleCurrencyChange(e.target.value)}
                                        allowDecimals
                                        disabled={!address}
                                    />
                                )}
                                {activeTabName === 'Withdraw' && (
                                    <CurrencyInput
                                        groupSeparator=","
                                        decimalSeparator="."
                                        className="input"
                                        value={amountTokenPlus}
                                        placeholder={t<string>('HomePage.Amount')}
                                        onChange={(e: any) => setInputValueHandlerWithdraw(e.target.value)}
                                        allowDecimals
                                        disabled={!address}
                                    />
                                )}
                            </div>
                        </InputWrapper>

                        <InputWrapper disabled>
                            <SettingsTitle margin="9px">
                                {activeTabName === 'Supply' ? 'Receive' : 'Send'}
                            </SettingsTitle>
                            <div className="input-wrapper">
                                <SelectWrapper>
                                    <CurrencyWrapper>
                                        <img src={asset.img} alt={asset.name} />
                                    </CurrencyWrapper>
                                    <span>{`${asset.name}${activeTabName !== 'Supply' ? '' : '+'}`}</span>
                                </SelectWrapper>
                                <input
                                    type="number"
                                    className="input"
                                    disabled
                                    value={activeTabName === 'Supply' ? amountTokenPlus : amount}
                                />
                            </div>
                        </InputWrapper>
                    </InputContainer>
                )}
                {!isMobile && (
                    <SettingsTitle>{`${t('SupplyPage.Balance')}: ${Number(ethers.formatUnits(userBalance, 18)).toFixed(
                        5
                    )} ${asset.name}; ${Number(ethers.formatUnits(tokenPlusBalance, 18)).toFixed(5)} ${
                        asset.name
                    }+; ${Number(ethers.formatUnits(balanceB, 18)).toFixed(5)} B`}</SettingsTitle>
                )}
                {activeTabName != 'Pool Run' && (
                    <Btn onClick={() => (activeTabName === 'Supply' ? depositHandler() : withdrawHandler())}>
                        {activeTabName} {asset.name}
                    </Btn>
                )}
                {activeTabName === 'Pool Run' && (
                    <>
                        <InputWrapper disabled>
                            <SettingsTitle margin="9px">Receive</SettingsTitle>
                            <div className="input-wrapper">
                                <SelectWrapper>
                                    <CurrencyWrapper>
                                        <img src={Btoken} alt={'B'} style={{ height: '20px' }} />
                                    </CurrencyWrapper>
                                    <span>B</span>
                                </SelectWrapper>
                                <input type="number" className="input" disabled value={balances[2]} />
                            </div>
                        </InputWrapper>
                        <InputWrapper disabled>
                            <SettingsTitle margin="9px">Receive</SettingsTitle>
                            <div className="input-wrapper">
                                <SelectWrapper>
                                    <CurrencyWrapper>
                                        <img src={allowedAssets.find((x) => x.name == 'USDT')!.img} alt={asset.name} />
                                    </CurrencyWrapper>
                                    <span>USDT</span>
                                </SelectWrapper>
                                <input type="number" className="input" disabled value={balances[0]} />
                            </div>
                        </InputWrapper>
                        <InputWrapper disabled>
                            <SettingsTitle margin="9px">Receive</SettingsTitle>
                            <div className="input-wrapper">
                                <SelectWrapper>
                                    <CurrencyWrapper>
                                        <img src={allowedAssets.find((x) => x.name == 'USDC')!.img} alt={asset.name} />
                                    </CurrencyWrapper>
                                    <span>USDC</span>
                                </SelectWrapper>
                                <input type="number" className="input" disabled value={balances[1]} />
                            </div>
                        </InputWrapper>

                        <Btn onClick={poolrunHandler} style={{ marginTop: '20px' }}>
                            {activeTabName}
                        </Btn>
                    </>
                )}
                <PurchaseWrapper>
                    <PurchaseDataList>
                        <PurchaseDataItem>
                            <SettingsTitle>{t('APY')}:</SettingsTitle>
                        </PurchaseDataItem>
                        <PurchaseDataItem>
                            <SettingsTitle>{t('SupplyPage.Exchange rate')}:</SettingsTitle>
                        </PurchaseDataItem>
                        <PurchaseDataItem>
                            <SettingsTitle>{`${asset.name}+ contract:`}</SettingsTitle>
                        </PurchaseDataItem>
                        <PurchaseDataItem>
                            <SettingsTitle>{`TVL`}</SettingsTitle>
                        </PurchaseDataItem>
                    </PurchaseDataList>
                    <PurchaseDataList>
                        <PurchaseDataItem>
                            <SettingsTitle>
                                {Number.parseFloat(apy).toFixed(2)} %
                                <InfoIcon
                                    data-tooltip-id="InfoIcon-tooltip"
                                    data-tooltip-content="Annual percentage yield is extrapolated from the price increase over a given period of time."
                                />
                                <Tooltip id="InfoIcon-tooltip" className="tooltip" />
                            </SettingsTitle>
                        </PurchaseDataItem>
                        <PurchaseDataItem>
                            <SettingsTitle>
                                1 {asset.name}+≈{Number(price).toString()} {asset.name}
                                <InfoIcon
                                    data-tooltip-id="InfoIcon-tooltip"
                                    data-tooltip-content={`${asset.name}/${asset.name}+ exchange rate is determined by a formula: total_supplied/tokens_minted. Because staked ${asset.name} is earning rewards, it grows in size vs. ${asset.name}+ over time. When you swap ${asset.name}+ back to ${asset.name}, you receive more ${asset.name} than you supplied before.`}
                                />
                                <Tooltip id="InfoIcon-tooltip" className="tooltip" />
                            </SettingsTitle>
                        </PurchaseDataItem>
                        <PurchaseDataItem>
                            <SettingsTitle
                                cursorPointer={true}
                                onClick={() => addToMM(assetTokenPlusContractAddress, asset.name + '+')}
                            >
                                {assetTokenPlusContractAddress}
                                <InfoIcon
                                    data-tooltip-id="InfoIcon-tooltip"
                                    data-tooltip-content="Official contract address"
                                />
                                <Tooltip id="InfoIcon-tooltip" className="tooltip" />
                            </SettingsTitle>
                        </PurchaseDataItem>
                        <PurchaseDataItem>
                            <SettingsTitle>
                                Total {Number(ethers.formatUnits(balanceUsdPool, 18)).toFixed(5)} {asset.name};{' '}
                                {/*Blocked in bets or
                                fees: {Number(ethers.formatUnits(balanceUsdPoolBlocked)).toFixed(5)} USDT*/}
                            </SettingsTitle>
                        </PurchaseDataItem>
                    </PurchaseDataList>
                </PurchaseWrapper>
            </div>
        </StyledSupply>
    )
}

const StyledSupply = styled.div`
    margin-bottom: 60px;
    .input_wrapper {
        position: relative;
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 32px;
    }
`

const Title = styled.h1`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    color: ${(props) => props.theme.titleColor};
    margin-bottom: 4px;

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

const Subtitle = styled.p`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: ${(props) => props.theme.subtitleColor};
    margin-bottom: 48px;

    @media screen and (max-width: 1000px) {
        margin-bottom: 40px;
    }

    @media screen and (max-width: 480px) {
        margin-bottom: 24px;
    }
`

export const TabContainer = styled.div`
    display: flex;
    position: relative;
    gap: 57px;
    margin-bottom: 30px;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${(props) => props.theme.tabBorderColor};
    }
    @media screen and (max-width: 768px) {
        gap: 48px;
    }

    @media screen and (max-width: 480px) {
        gap: 0;
        justify-content: space-between;
    }
`

export const Tab = styled.div<{ active?: boolean }>`
    padding: 8px 4px;
    position: relative;
    cursor: pointer;

    border-bottom: ${(props) => (props.active ? '2px solid #3fe7be' : '2px solid transparent')};

    span {
        color: ${(props) => (props.active ? '#3fe7be' : '#8a8f99')};
        font-weight: ${(props) => (props.active ? '600' : '400')};
        font-size: 14px;
        line-height: 140%;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
    }
    h3 {
        color: var(--green);
        font-weight: 400;
        font-size: 14px;
        margin-left: 5px;
    }
`

export const SettingsTitle = styled.span<{ margin?: string; cursorPointer?: boolean }>`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: ${(props) => props.margin ?? '0px'};
    color: #8a8f99;
    display: flex;
    cursor: ${(props) => (props.cursorPointer ? 'pointer' : '	default')};

    svg {
        margin-left: 8px;
        margin-top: 3px;
    }
`

const InputContainer = styled.div`
    gap: 16px;
    display: flex;
    margin-bottom: 12px;

    .title {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 40px;
    }
`

export const InputWrapper = styled.div<{ disabled?: boolean }>`
    position: relative;
    .wrapper {
        display: flex;
        flex-direction: column;
    }

    .input-wrapper {
        position: relative;
        width: 340px;
        border: 1px solid #e9ecf2;
        border: ${({ disabled, theme }) =>
            disabled ? `1px solid ${theme.inputDisabledBorderColor}` : `1px solid ${theme.inputBorderColor}`};

        border-radius: 8px;
        padding: 12px;
        display: flex;

        background-color: ${({ disabled, theme }) =>
            disabled ? theme.inputWrapperDisabledColor : theme.inputWrapperColor};

        @media screen and (max-width: 786px) {
            width: 100%;
        }
    }

    .input {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 21px;
        color: ${(props) => props.theme.selectColor};
        text-align: right;
        width: 100%;
        margin-left: auto;
        outline: none;
        &::placeholder {
            font-family: 'Inter';
            font-weight: 400;
            font-size: 21px;
            line-height: 30px;
            color: ${(props) => props.theme.inputPlaceholderColor};
            text-align: right;
        }
    }

    @media screen and (max-width: 786px) {
        width: 100%;
        .input {
            width: 100%;
        }
    }
`

const TitleContainer = styled.div`
    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        width: 340px;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`

export const SelectWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    .colored {
        fill: #fff;
    }
    span {
        font-family: 'Inter';
        font-weight: 400;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 140%;
        color: ${(props) => props.theme.selectColor};
    }
    path {
        fill: ${(props) => props.theme.arrowBackgroundColor};
    }
`

export const CurrencyWrapper = styled.div`
    margin-right: 4px;
    display: flex;
    justify-content: center;
`

export const Btn = styled.button`
    background: #3fe7be;
    border-radius: 8px;
    padding: 13px 48px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #238069;
    align-self: flex-start;
    margin-bottom: 24px;
    transition: 0.2s all ease;
    @media screen and (max-width: 1000px) {
        margin-bottom: 32px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    &:hover,
    &:active {
        color: #fff;
    }
`

const PurchaseWrapper = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 80px;
`

const PurchaseDataList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 8px;
`
const PurchaseDataItem = styled.li``

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ArrowIcon = styled(Arrow)`
    path {
        fill: ${(props) => props.theme.arrowBackgroundColor};
    }
`

export { SupplyPage }
