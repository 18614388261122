import * as React from 'react'
import Countdown from 'react-countdown'
import styled from 'styled-components'
import { BetCFDVm } from '../../../@types/Game/bet'
import { allowedCurrencies } from '../../../lib/data'
import { gameSlice } from '../../../store/reducers/gameSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { allowedTimeframes } from '../../../lib/data'
import { useEffect, useRef } from 'react'
import { SimpleChart } from '../../../components/BetChart/BetChartSimpleComponent'
import { convertBetCFDVmToBetFPCVm } from '../../../lib/sharedFunctions'

const Pending = () => <span>Pending...</span>

export interface IBetItemProps {
    bet: BetCFDVm
}

export const BetItem: React.FunctionComponent<IBetItemProps> = ({ bet }) => {
    const { points } = useAppSelector((state) => state.pointsSlice)
    const { themeName } = useAppSelector((state) => state.appSettings)
    const { currencyPrice, rewardPercent } = useAppSelector((state) => state.gameSlice)
    const { setTimeframe, setIsPopupOpen, setClosedBet, setActiveBet } = gameSlice.actions
    const dispatch = useAppDispatch()

    const betCurrency = allowedCurrencies.find((x) => x.ticker === bet.currency)
    const directionUp = bet.betType === 0

    const isWinClosed =
        (directionUp && bet.lockPrice < bet.closePrice) || (!directionUp && bet.lockPrice > bet.closePrice)

    const isWinActive =
        (directionUp && bet.lockPrice < currencyPrice) || (!directionUp && bet.lockPrice > currencyPrice)

    const datetime = new Date(Date.parse(bet.lockedAt) + bet.timeframe * 1000)

    const countdown = datetime.getTime() - Date.now() > 0 || bet.closePrice === 0
    const isWin = !!countdown ? isWinActive : isWinClosed
    const chartRef = useRef<SimpleChart>(null)

    const chartPoints = !countdown
        ? bet.points
        : points.pointsList?.filter(
              (x) =>
                  Date.parse(x.timeStamp) >= Date.parse(bet.lockedAt) && new Date(Date.parse(x.timeStamp)) <= datetime
          )

    useEffect(() => {
        chartRef.current?.bet(bet)
    }, [chartRef, bet])

    function getRealReward() {
        const plsorted = points?.pointsList
            .map((x) => x)
            .sort((a, b) => {
                let dateA = new Date(a.timeStamp)
                let dateB = new Date(b.timeStamp)

                if (dateA < dateB) {
                    return -1
                }
                if (dateA > dateB) {
                    return 1
                }
                return 0
            })
        const close = plsorted[plsorted.length - 1].value
        let diffMod = close > bet.lockPrice ? close - bet.lockPrice : bet.lockPrice - close
        let realPercent = ((diffMod * 1_000_000) / bet.lockPrice) * 100 * bet.leverage
        if (realPercent > rewardPercent * 1_000_000) {
            realPercent = rewardPercent * 1_000_000
        }
        let realReward = bet.amount + (bet.amount * realPercent) / 100_000_000
        return realReward
    }

    return (
        <DataContentItem
            key={bet.betId}
            theme={themeName}
            onClick={() => {
                if (countdown) {
                    dispatch(
                        setTimeframe(
                            allowedTimeframes.find((timeframe) => timeframe.value === (bet as BetCFDVm).timeframe)!.name
                        )
                    )
                    dispatch(setActiveBet(bet))
                } else {
                    let asFpc = convertBetCFDVmToBetFPCVm(bet)
                    asFpc.potentialReward = bet.tradeResult - bet.amount
                    console.log(bet.tradeResult)
                    if (bet.tradeResult < 0) {
                        console.log(bet.tradeResult)
                        asFpc.amount = bet.amount - bet.tradeResult * -1
                    }
                    dispatch(setClosedBet(asFpc))
                    dispatch(setIsPopupOpen(true))
                }
            }}
        >
            <span className="center mobile-display-none">
                <img src={betCurrency?.img} alt={betCurrency?.name} />
            </span>
            <span>
                <svg
                    className={`first_td_adaptive ${!directionUp && 'rotate180deg'}`}
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M16 12L22 20H10L16 12Z" fill={`var(--${directionUp ? 'green' : 'pink'})`} />
                </svg>
            </span>
            <span className="center">${bet.lockPrice}</span>

            <span className="center">
                {countdown ? <Countdown date={datetime} renderer={renderer} /> : '$' + bet.closePrice}
            </span>
            <span className="chart_td center mobile-display-none" style={{ cursor: 'pointer' }}>
                <SimpleChart
                    ref={chartRef}
                    points={chartPoints}
                    timeframe={bet.timeframe}
                    theme={themeName}
                    priceCurrency="$"
                />
            </span>

            {countdown && (
                <span className={`price-${isWin ? 'increase' : 'drop'} last`}>
                    {isWin
                        ? `+${(getRealReward() - bet.amount).toFixed(2)}`
                        : `-${(getRealReward() - bet.amount).toFixed(2)}`}
                </span>
            )}
            {!countdown && (
                <span className={`price-${isWin ? 'increase' : 'drop'} last`}>
                    {isWin
                        ? `+${(bet.tradeResult - bet.amount).toFixed(2)}`
                        : `-${(bet.tradeResult + bet.amount).toFixed(2)}`}
                </span>
            )}
        </DataContentItem>
    )
}
// @ts-ignore
const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const totalHours = formatTimePart(hours + days * 24)
    minutes = formatTimePart(minutes)
    seconds = formatTimePart(seconds)
    return completed ? (
        <Pending />
    ) : hours > 0 ? (
        <span>
            {totalHours}:{minutes}:{seconds}
        </span>
    ) : (
        <span>
            {minutes}:{seconds}
        </span>
    )
}

function formatTimePart(part: any) {
    part = part.toString() as string
    if (part.length < 2) {
        return `0${part}`
    }
    return part
}

const DataContentItem = styled.div<{ theme: string }>`
    display: grid;
    gap: 32px;
    grid-template-columns: 32px 51px 84px repeat(3, 1fr);
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => (theme === 'light' ? '#f6f8fc' : '#30373C')};
    }

    @media screen and (max-width: 768px) {
        gap: 24px;
        grid-template-columns: 32px 32px 84px repeat(3, 1fr);
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(4, 1fr);
        padding: 0 16px 0 8px;

        .mobile-display-none {
            display: none;
        }
    }

    .rotate180deg {
        transform: rotate(180deg);
    }

    .chart_td canvas {
        height: 20px !important;
        width: 100% !important;
    }
`
