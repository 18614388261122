import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ethers } from 'ethers'
import { BetFPCVm} from '../../@types/Game/bet'
import { AssetTicker, CurrencyTicker, TimeframeName } from '../../@types/Game/game'
import { allowedAssets, allowedCurrencies, allowedTimeframes } from '../../lib/data'

export const initialState = {
    ref: ethers.ZeroAddress,
    asset: allowedAssets[0],
    userBalance: 0n,
    gameFPCContractAddress: import.meta.env.VITE_APP_GAME_FPC_CONTRACT!,
    gameCFDContractAddress: import.meta.env.VITE_APP_GAME_CFD_CONTRACT!,
    poolContractAddress: import.meta.env.VITE_APP_POOL_CONTRACT!,
    assetContractAddress: ethers.ZeroAddress,
    rewardPercent: 80,
    timeframe: allowedTimeframes[2],
    currency: allowedCurrencies[0],
    currencyPrice: 0 as number,
    currencyPrevPrice: 0 as number,
    currencyRoundId: '0',
    isPopupOpen: false,
    activeBet: {} as {} | BetFPCVm,
    closedBet: {} as {} | BetFPCVm,
}
export const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setAsset(state, action: PayloadAction<AssetTicker>) {
            state.asset = allowedAssets.find((x) => x.name === action.payload) ?? allowedAssets[0]
        },
        setAssetContract(state, action: PayloadAction<string>) {
            state.assetContractAddress = action.payload
        },
        setUserBalance(state, action: PayloadAction<bigint>) {
            state.userBalance = action.payload
        },
        setRewardPercent(state, action: PayloadAction<number>) {
            state.rewardPercent = action.payload
        },
        setTimeframe(state, action: PayloadAction<TimeframeName>) {
            state.timeframe = allowedTimeframes.find((x) => x.name === action.payload)!
        },
        setCurrency(state, action: PayloadAction<CurrencyTicker>) {
            state.currency = allowedCurrencies.find((x) => x.ticker === action.payload) ?? allowedCurrencies[0]
        },
        setCurrencyPrice(state, action: PayloadAction<{ value: number; roundId: string }>) {
            if (action.payload.roundId !== state.currencyRoundId) {
                state.currencyPrevPrice = state.currencyPrice
                state.currencyPrice = action.payload.value
                state.currencyRoundId = action.payload.roundId
            }
        },
        setRef(state, action: PayloadAction<string>) {
            state.ref = action.payload
        },
        setIsPopupOpen(state, action: PayloadAction<boolean>) {
            state.isPopupOpen = action.payload
        },
        setClosedBet(state, action: PayloadAction<BetFPCVm>) {
            state.closedBet = action.payload
        },
        setActiveBet(state, action: PayloadAction<{} | BetFPCVm>) {
            state.activeBet = action.payload
        },
    },
})

export default gameSlice.reducer
