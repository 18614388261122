import { useEffect, useState } from 'react'
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr'
import * as SignalR from '@aspnet/signalr'
import { useAppDispatch, useAppSelector } from './redux'
import { gameSlice } from '../store/reducers/gameSlice'
import { BetCFDVm, BetFPCVm } from '../@types/Game/bet'
import { historySlice } from '../store/reducers/historyFPCSlice'
import { useAccount } from 'wagmi'
import { useEthersProvider } from './useEthersProvider'
import { getBalance } from '../store/api/contracts'

export const useUserNotifications = () => {
    const provider = useEthersProvider()
    const { address } = useAccount()
    const { assetContractAddress } = useAppSelector((state) => state.gameSlice)
    const dispatch = useAppDispatch()
    const [connection, setConnection] = useState<HubConnection>()
    const { setIsPopupOpen, setClosedBet, setActiveBet, setUserBalance } = gameSlice.actions
    const { refreshClosedBets, refreshActiveBets } = historySlice.actions

    useEffect(() => {
        if (!!address) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(`${import.meta.env.VITE_APP_BACKEND_URI!}/wssuser?address=${address}`, {
                    withCredentials: false,
                    skipNegotiation: true,
                    transport: SignalR.HttpTransportType.WebSockets,
                })
                .withAutomaticReconnect()
                .build()

            setConnection(newConnection)
        } else if (!!connection) {
            connection.stop()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address])

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    connection.on('BetFpcClosed', (payload: BetFPCVm) => {
                        dispatch(setClosedBet(payload))
                        dispatch(setActiveBet({}))
                        dispatch(setIsPopupOpen(true))
                        dispatch(refreshClosedBets())
                        dispatch(refreshActiveBets())

                        getBalance(assetContractAddress!, address!, provider).then((b) => dispatch(setUserBalance(b)))
                    })
                    connection.on('BetFpcAdded', (payload: BetFPCVm) => {
                        dispatch(setActiveBet(payload))
                        dispatch(refreshClosedBets())
                        dispatch(refreshActiveBets())
                    })
                    connection.on('BetCfdClosed', (payload: BetCFDVm) => {
                        let asFpc = payload as unknown as BetFPCVm
                        asFpc.potentialReward = payload.tradeResult - payload.amount
                        if (payload.tradeResult < 0) {
                            asFpc.amount = payload.amount - payload.tradeResult * -1
                        }
                        dispatch(setClosedBet(asFpc))
                        dispatch(setActiveBet({}))
                        dispatch(setIsPopupOpen(true))
                        dispatch(refreshClosedBets())
                        dispatch(refreshActiveBets())
                        getBalance(assetContractAddress!, address!, provider).then((b) => dispatch(setUserBalance(b)))
                    })
                    connection.on('BetCfdAdded', (payload: BetCFDVm) => {
                        dispatch(setActiveBet(payload))
                        dispatch(refreshClosedBets())
                        dispatch(refreshActiveBets())
                    })
                })
                .catch((e: any) => console.log('Connection failed: ', e))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection])
}
