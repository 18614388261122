import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
//@ts-ignore
import { BetChart } from './BetChart'
import './BetChartComponent.css'

interface ChartProps {
    points: { timeStamp: string; value: number }[]
    timeframe: number
    theme: string
    priceCurrency: string
}
export interface Chart {
    bet(activeBet: any): void
    getBet(): null|{ x: number; y: number }
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Chart = forwardRef<Chart, ChartProps>((props, ref) => {
    const { points, timeframe, theme, priceCurrency } = props
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [chart, setChart] = useState<null | BetChart>(null)
    const [bet, setBet] = useState<null | any>(null)
    useImperativeHandle(
        ref,
        () => ({
            bet(activeBet: any) {
                setBet(activeBet)
            },
            getBet() {
                return chart? chart.bet : null
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [canvasRef, chart, chart?.bet]
    )

    useEffect(() => {
        if (canvasRef.current) {
            const chart = new BetChart(canvasRef.current, {priceCurrency})
            setChart(chart)
            return () => chart.destroy()
        }
    }, [canvasRef, priceCurrency])

    useEffect(() => {
        if (!!bet && bet.betId && !!chart) {
            chart.setBet(bet.lockedAt, bet.lockPrice)
        } else if ((!bet || bet.betId == null) && !!chart) {
            chart.setBet(false)
        }
    }, [bet, chart])

    useEffect(() => {
        chart?.setOptions({ points, timeframe, theme })
    }, [chart, points, timeframe, theme])

    return <canvas ref={canvasRef} />
})
