import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import 'swiper/css'
import 'swiper/css/pagination'
import { BrowserRouter } from 'react-router-dom'
import { polygon, polygonMumbai, bsc } from 'wagmi/chains'
import { Provider } from 'react-redux'
import { setupStore } from './store/store.ts'
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { publicProvider } from 'wagmi/providers/public'
import TagManager from 'react-gtm-module'
import { BinanceWalletConnector } from './utils/BinanceWalletConnector.ts'

const { chains, publicClient, webSocketPublicClient } = configureChains([bsc], [publicProvider()])
const config = createConfig({
    autoConnect: true,
    connectors: [
        new MetaMaskConnector({ chains }),
        new CoinbaseWalletConnector({
            chains,
            options: {
                appName: 'BorB',
            },
        }),
        new BinanceWalletConnector(),
        new InjectedConnector({
            chains,
            options: {
                name: 'Injected',
                shimDisconnect: true,
            },
        }),
    ],
    publicClient,
    //webSocketPublicClient,
})
const store = setupStore()

const tagManagerArgs = {
    gtmId: 'G-3519V8FJ8M',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <WagmiConfig config={config}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </WagmiConfig>
)
